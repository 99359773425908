import { useAppSelector } from 'store';
import { createSelector } from 'reselect';

/**
 *
 * @param {*} permissions
 * @returns
 */
export function useHasPermission(permissions) {
  const hasPermission = useAppSelector(selectHasSomePermissions(permissions));

  return hasPermission;
}

export function useHasSomePermission(permissions) {
  const hasPermission = useAppSelector(selectHasSomePermissions(permissions));

  return hasPermission;
}

export function useHasEveryPermission(permissions) {
  const hasPermission = useAppSelector(selectHasEveryPermission(permissions));

  return hasPermission;
}

export const selectPermissableMenuItems = (menuItems) => {
	return ({ auth }) => {
		const { permissions: userPermissions } = auth;
		const findPermissable = (final, item) => {
			const copy = { ...item };
			const { permission } = copy;

			const hasPermission = permission === false ? true : checkUserHasSomePermissions(permission, userPermissions);

			if (hasPermission) {
				final.push(copy);

				if (copy.children) {
					copy.children = copy.children.reduce(findPermissable, []);
				}
			}

			return final;
		};

		return menuItems.reduce(findPermissable, []);
	};
};

export const selectHasSomePermissions = (permissions) => {
	return createSelector(
		({ auth }) => auth.permissions,
		(userPermissions) => {
			const hasPermissions = checkUserHasSomePermissions(permissions, userPermissions);

			return hasPermissions;
		},
	);
};

export const selectHasEveryPermission = (permissions) => {
	return createSelector(
		({ auth }) => auth.permissions,
		(userPermissions) => {
			const hasPermissions = checkUserHasEveryPermissions(permissions, userPermissions);

			return hasPermissions;
		},
	);
};

export const selectIsAuthenticated = createSelector(
	({ auth }) => auth,
	(auth) => auth.isAuthenticated,
);

function checkUserHasSomePermissions(permissions, userPermissions) {
	if (!Array.isArray(userPermissions) || userPermissions.length === 0) {
		return false;
	}

	if (!permissions || (typeof permissions !== 'string' && !Array.isArray(permissions))) {
		return false;
	}

	const permissionsArray = typeof permissions === 'string' ? [permissions] : permissions;

	return permissionsArray.some((perm) => userPermissions.includes(perm));
}

function checkUserHasEveryPermissions(permissions, userPermissions) {
	return (typeof permissions === 'string' ? [permissions] : permissions).every((x) => !!~userPermissions.indexOf(x));
}
