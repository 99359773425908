import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import * as types from '../../../actions/actionTypes';
import { v4 as uuidv4 } from "uuid";
import { initialState, initialFilterState } from './availabilityState';
import { generateDateArray } from '../../../lib/misc';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    setSearchContext: (state, action) => {
      state.config = action.payload.config;
      state.searchContext = action.payload.searchContext;
    },
    setViewBaseDate: (state, action) => {
      state.appliedFilters.viewBaseDate = action.payload.viewBaseDate;
      state.sortOrderBaseDate = action.payload.sortOrderBaseDate ?
        action.payload.sortOrderBaseDate
        : state.sortOrderBaseDate;
    },
    setSelectedCalendarViewBaseDate: (state, action) => {
      state.selectedCalendarViewBaseDate = dayjs(action.payload).format(DEFAULT_DATE_FORMAT);
    },
    setSelectedCalendarId: (state, action) => {
      state.selectedCalendarId = action.payload.calendarId;
      state.selectedCalendarViewBaseDate = action.payload.selectedCalendarViewBaseDate;
    },
    setActivePage: (state, action) => {
      state.pageNumber = action.payload;
    },
    setAppliedFilters: (state, action) => {
      state.appliedFilters = action.payload.appliedFilters;
      state.calendars = action.payload.calendars ?? state.calendars;
      state.scanResults = action.payload.scanResults ?? state.scanResults;
      state.availability = action.payload.availability ?? state.availability;
      state.pageNumber = action.payload.pageNumber ?? state.pageNumber;
      state.sortOrderBaseDate = action.payload.sortOrderBaseDate ?? state.sortOrderBaseDate;
    },
    flushCalendars: (state) => {
      state.calendars = {};
    },
    flushAvailabilityAndScanResults: (state) => {
      state.availability = {};
      state.scanResults = {};
    },
    setSkipAheadBehaviorSettled: (state, action) => {
      state.hasSkipAheadToDateBehaviorSettled = action.payload.settled;
      state.appliedFilters.viewBaseDate = action.payload.viewBaseDate ?
        dayjs(action.payload.viewBaseDate).format(DEFAULT_DATE_FORMAT)
        : state.appliedFilters.viewBaseDate;
    },
    logNoSearchOrNextScanNeededBatchCompleted: (state, action) => {
      if (action.payload.isPreferredBatch) {
        state.initialBatchLog.preferred.searchRequired = false;
        state.initialBatchLog.preferred.scanRequired = false;
        state.initialBatchLog.hasPreferredBatchCompleted = true;
        state.initialBatchLog.hasCompleted = state.initialBatchLog.hasNotPreferredBatchCompleted ?? false;
      } else {
        state.initialBatchLog.notPreferred.searchRequired = false;
        state.initialBatchLog.notPreferred.scanRequired = false;
        state.initialBatchLog.hasNotPreferredBatchCompleted = true;
        state.initialBatchLog.hasCompleted = state.initialBatchLog.hasPreferredBatch ?
          state.initialBatchLog.hasPreferredBatchCompleted ?? false
          : true;
      }
    },
    searchCalendars: (state, action) => {
      state.isSearchingCalendars = true;
      if (!state.initialBatchLog.calendarSearchStartedAt) {
        state.initialBatchLog.calendarSearchStartedAt = dayjs().format();
      }
    },
    searchCalendars_SUCCESS: (state, action) => {
      state.isSearchingCalendars = false;
      writeCalendarsToCache(state, action.payload.data.calendars);
      state.hasDoneFirstCalendarSearch = true;
      state.initialBatchLog.calendarSearchCompletedAt = state.initialBatchLog.calendarSearchCompletedAt ?? dayjs().format();
    },
    searchCalendars_FAIL: (state, action) => {
      state.isSearchingCalendars = false;
    },
    searchAvailability: (state, action) => {
      writeInFlightSearchToCache(state, action);

      const updatedLogInfo = {}

      if (!state.initialBatchLog.hasCompleted) {
        updatedLogInfo.searchRequired = true;
        updatedLogInfo.searchStartedAt = dayjs().format();
        updatedLogInfo.searchCalendarIds = action.payload.request.data.calendarIds;
        updatedLogInfo.searchMinStartDate = action.payload.request.data.minStartDate;
        updatedLogInfo.searchMaxStartDate = action.payload.request.data.maxStartDate;

        if (!action.payload.logInfo.nextScanRequired) {
          updatedLogInfo.nextScanRequired = false;
        }

        if (action.payload.logInfo.isPreferredBatch) {
          state.initialBatchLog.preferred = { ...state.initialBatchLog.preferred, ...updatedLogInfo }
        } else {
          state.initialBatchLog.notPreferred = { ...state.initialBatchLog.notPreferred, ...updatedLogInfo }
        }
      }

    },
    searchAvailability_SUCCESS: (state, action) => {
      if (action.meta.previousAction.payload.request.data.filterContextRefId === state.appliedFilters.availabilityValidRefId) {
        action.meta.previousAction.payload.request.data.calendarIds.forEach(calendarId => {
          delete state.inFlightSearchLog[calendarId];
        })
        writeAvailabilityToCache(state, action);

        const updatedLogInfo = {}

        if (!state.initialBatchLog.hasCompleted) {
          updatedLogInfo.searchCompletedAt = dayjs().format();

          if (action.meta.previousAction.payload.logInfo.isPreferredBatch) {
            state.initialBatchLog.preferred = { ...state.initialBatchLog.preferred, ...updatedLogInfo }

            if (!action.meta.previousAction.payload.logInfo.isFirstScan) {
              if (!state.initialBatchLog.preferred.nextScanRequired || (state.initialBatchLog.preferred.nextScanRequired && state.initialBatchLog.preferred.nextScanCompletedAt)) {
                state.initialBatchLog.hasPreferredBatchCompleted = true;
                state.initialBatchLog.hasCompleted = state.initialBatchLog.hasNotPreferredBatchCompleted ?? false;
              }
            }
          } else {
            state.initialBatchLog.notPreferred = { ...state.initialBatchLog.notPreferred, ...updatedLogInfo }

            if (!action.meta.previousAction.payload.logInfo.isFirstScan) {
              if (!state.initialBatchLog.notPreferred.nextScanRequired || (state.initialBatchLog.notPreferred.nextScanRequired && state.initialBatchLog.notPreferred.nextScanCompletedAt)) {
                state.initialBatchLog.hasNotPreferredBatchCompleted = true;
                state.initialBatchLog.hasCompleted = state.initialBatchLog.hasPreferredBatch ?
                  state.initialBatchLog.hasPreferredBatchCompleted ?? false
                  : true;
              }
            }
          }
        }

      }
    },
    searchAvailability_FAIL: (state, action) => {
      action.meta.previousAction.payload.request.data.calendarIds.forEach(calendarId => {
        delete state.inFlightSearchLog[calendarId];
      })
    },
    scanAvailability: (state, action) => {
      writeInFlightScanToCache(state, action);
      const updatedLogInfo = {}

      if (!state.initialBatchLog.hasCompleted) {
        if (action.payload.logInfo.isFirstScan) {
          updatedLogInfo.firstScanStartedAt = dayjs().format();
        }

        if (!action.payload.logInfo.isFirstScan) {
          updatedLogInfo.nextScanRequired = true;
          updatedLogInfo.nextScanStartedAt = dayjs().format();
          updatedLogInfo.nextScanCalendarIds = action.payload.request.data.calendarIds;
          updatedLogInfo.nextScanMinStartDate = action.payload.request.data.minStartDate;
          updatedLogInfo.nextScanMaxStartDate = action.payload.request.data.maxStartDate;
        }

        if (!action.payload.logInfo.isFirstScan && action.payload.logInfo.searchRequired === false) {
          updatedLogInfo.searchRequired = false;
        }

        if (action.payload.logInfo.isPreferredBatch) {
          state.initialBatchLog.preferred = { ...state.initialBatchLog.preferred, ...updatedLogInfo }
        } else {
          state.initialBatchLog.notPreferred = { ...state.initialBatchLog.notPreferred, ...updatedLogInfo }
        }
      }

    },
    scanAvailability_SUCCESS: (state, action) => {
      if (action.meta.previousAction.payload.request.data.filterContextRefId === state.appliedFilters.availabilityValidRefId) {
        action.meta.previousAction.payload.request.data.calendarIds.forEach(calendarId => {
          delete state.inFlightScanLog[calendarId];
        })
        writeScanResultsToCache(state, action);

        const updatedLogInfo = {}

        if (!state.initialBatchLog.hasCompleted) {
          if (action.meta.previousAction.payload.logInfo.isFirstScan) {
            updatedLogInfo.firstScanCompletedAt = dayjs().format();
          } else {
            updatedLogInfo.nextScanCompletedAt = dayjs().format();
          }

          if (action.meta.previousAction.payload.logInfo.isPreferredBatch) {
            state.initialBatchLog.preferred = { ...state.initialBatchLog.preferred, ...updatedLogInfo }

            if (!action.meta.previousAction.payload.logInfo.isFirstScan) {
              if (!action.meta.previousAction.payload.logInfo.searchRequired || (action.meta.previousAction.payload.logInfo.searchRequired && state.initialBatchLog.preferred.searchCompletedAt)) {
                state.initialBatchLog.hasPreferredBatchCompleted = true;
                state.initialBatchLog.hasCompleted = state.initialBatchLog.hasNotPreferredBatchCompleted ?? false;
              }
            }
          } else {
            state.initialBatchLog.notPreferred = { ...state.initialBatchLog.notPreferred, ...updatedLogInfo }

            if (!action.meta.previousAction.payload.logInfo.isFirstScan) {
              if (!action.meta.previousAction.payload.logInfo.searchRequired || (action.meta.previousAction.payload.logInfo.searchRequired && state.initialBatchLog.notPreferred.searchCompletedAt)) {
                state.initialBatchLog.hasNotPreferredBatchCompleted = true;
                state.initialBatchLog.hasCompleted = state.initialBatchLog.hasPreferredBatch ?
                  state.initialBatchLog.hasPreferredBatchCompleted ?? false
                  : true;
              }
            }
          }
        }
      }
    },
    scanAvailability_FAIL: (state, action) => {
      action.meta.previousAction.payload.request.data.calendarIds.forEach(calendarId => {
        delete state.inFlightScanLog[calendarId];
      })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(types.SET_INITIAL_AVAILABILITY_SEARCH_STATE, (state, action) => {
        state.searchContext = {
          availabilitySearchCriteria: action.availability.availabilitySearchCriteria,
          calendarSearchCriteria: action.availability.calendarSearchCriteria
        };
        state.sortOrderBaseDate = action.availability.availabilitySearchCriteria.minStartDate;
        const initialFilters = {
          ...state.appliedFilters,
          ...getFilterStateFromSearchContext(
            action.availability.calendarSearchCriteria,
            action.availability.availabilitySearchCriteria
          )
        }
        state.appliedFilters = initialFilters;
        state.initialFilters = initialFilters;

      })
      .addCase(types.SET_RESCHEDULE_AVAILABILITY_SEARCH_STATE, (state, action) => {
        state.searchContext = {
          availabilitySearchCriteria: {
            //TODO discuss better solution in refactor - reschedule is not passing all the criteria
            ...state.searchContext.availabilitySearchCriteria,
            ...action.availability.availabilitySearchCriteria
          },
          calendarSearchCriteria: action.availability.calendarSearchCriteria
        };
        state.sortOrderBaseDate = action.availability.availabilitySearchCriteria.minStartDate;
        const initialFilters = {
          ...state.appliedFilters,
          ...getFilterStateFromSearchContext(
            action.availability.calendarSearchCriteria,
            action.availability.availabilitySearchCriteria
          )
        }
        state.appliedFilters = initialFilters;
        state.initialFilters = initialFilters;
      })
      .addCase(types.SET_ACTIVE_CAREORDERVISIT_DETAILS, (state, action) => {
        const activeCareOrderDetails = action.data.careOrderWorkflowDetails;
        let hasAppointment = activeCareOrderDetails.appointments && activeCareOrderDetails.appointments.length === 1;
        let appointment = hasAppointment ? activeCareOrderDetails.appointments[0] : null;

        if (appointment) {
          state.initialFilters.serviceName = appointment.serviceDisplayName ?? '';
          state.appliedFilters.serviceName = appointment.serviceDisplayName ?? '';
          state.initialFilters.siteName = appointment.siteName ?? '';
          state.appliedFilters.siteName = appointment.siteName ?? '';
        }
      })
      .addCase(types.APPOINTMENT_DETAILS_SUCCESS, (state, action) => {
        return { ...initialState }
      })
      .addCase(types.SET_ACTIVE_PATIENT, (state, action) => {
        return { ...initialState }
      })
      .addCase(types.RESET_AVAILABILITY_STATE, (state, action) => {
        return { ...initialState }
      })
      // discussion point, reset availability state (above) is being called from PatientDetailsView, 
      // shouldn't the availability slice own when/why it clears? 
      .addCase(types.ON_PATIENT_DETAILS_VIEW_MOUNT, (state, action) => {
        return { ...initialState }
      })
  }
});
export const {
  flushCalendars,
  flushAvailabilityAndScanResults,
  logNoSearchOrNextScanNeededBatchCompleted,
  scanAvailability,
  searchAvailability,
  searchCalendars,
  setActivePage,
  setAppliedFilters,
  setSelectedCalendarId,
  setSelectedCalendarViewBaseDate,
  setSkipAheadBehaviorSettled,
  setViewBaseDate,
} = availabilitySlice.actions;
export default availabilitySlice.reducer;

const getFilterStateFromSearchContext = (calendarSearchCriteria, availabilitySearchCriteria) => {
  return {
    availabilityValidRefId: uuidv4(),
    viewBaseDate: dayjs(availabilitySearchCriteria.minStartDate).format(DEFAULT_DATE_FORMAT),

    zipCode: calendarSearchCriteria.distanceFilter.zipCode,
    radius: calendarSearchCriteria.distanceFilter.maxDistanceInMiles || initialFilterState.radius,
    gender: calendarSearchCriteria.providerCriteria.genderCode || initialFilterState.gender,
    payorType: availabilitySearchCriteria.payorTypeName || initialFilterState.payorType,
    specialtyId: calendarSearchCriteria.providerCriteria.specialtyId || initialFilterState.specialtyId,

    sortOrder: calendarSearchCriteria.responseConfig.sortOrder || initialFilterState.sortOrder,
    insuranceCarrierId: availabilitySearchCriteria.insuranceProviderId || initialFilterState.insuranceCarrierId,
    language: calendarSearchCriteria.providerCriteria.languageName || initialFilterState.language,

    daysOfWeek: availabilitySearchCriteria.daysOfWeek || initialFilterState.daysOfWeek,
  }
}

//#region cache writers
function writeCalendarsToCache(state, calendars) {
  calendars.forEach(calendar => {
    state.calendars[calendar.calendarId] = {
      id: calendar.calendarId,
      bio: calendar.provider?.bio,
      isPreferred: calendar.isPreferredCalendar,
      displayName: calendar.serviceProviderName,
      providerFirstName: calendar.provider?.firstName,

      providerLastName: calendar.provider ? calendar.provider.lastName : calendar.equipment ? calendar.equipment.equipmentName : calendar.clinic.clinicName,

      specialtyName: calendar.serviceProviderSettings?.primarySpecialtyName,
      locationName: calendar.site?.locationName,
      distanceInMiles: calendar.site?.distanceInMiles,
      address: {
        ...calendar.site?.address,
        zipCode: Number(calendar.site?.address?.zipCode)
      },
      imageFileName: calendar.serviceProviderSettings?.imageFilename,
      genderCode: calendar.provider?.genderCode,
      serviceProviderTypeId: calendar.serviceProviderSettings?.serviceTypeId,
      serviceProviderTypeName: calendar.serviceProviderSettings?.serviceType,
      languages: calendar.serviceProviderSettings?.languages,
      telephone: calendar.site?.telephone,
      suppressTelephone: calendar.site?.suppressTelephone,
      suppressAddress: calendar.site?.suppressAddress,
      isAppointmentRequestEnabled: calendar.serviceProviderSettings?.isAppointmentRequestEnabled,
      isAppointmentRequestWithAvailabilityEnabled: calendar.serviceProviderSettings?.isAppointmentRequestWithAvailabilityEnabled,
      isAppointmentRequestWithImmediateAvailabilityEnabled: calendar.serviceProviderSettings?.isAppointmentRequestWithImmediateAvailabilityEnabled,
      referrefNotes: calendar.serviceProviderSettings?.referrefNotes,
      informationForPatient: calendar.serviceProviderSettings?.informationForPatient,
      serialNumber: calendar.equipment?.serialNumber,
      assetTag: calendar.equipment?.assetTag,
      npi: calendar.serviceProviderSettings?.npi,
    }
  })
}

function writeAvailabilityToCache(
  state,
  action
) {
  action.meta.previousAction.payload.request.data.calendarIds.forEach(calendarId => {
    state.availability[calendarId] = state.availability[calendarId] ?? {};
    state.scanResults[calendarId] = state.scanResults[calendarId] ?? {};
    for (let date = dayjs(action.meta.previousAction.payload.request.data.minStartDate); date.diff(action.meta.previousAction.payload.request.data.maxStartDate, 'd') <= 0; date = date.add(1, 'day')) {
      let slots = action.payload.data.timeSlots
        .filter(timeSlot => {
          return (
            timeSlot.calendarId === calendarId &&
            dayjs(timeSlot.date).diff(date, 'd') === 0
          )
        })
        .map(timeSlot => {
          return {
            ...timeSlot,
            id: timeSlot.availabilityId,
            startAt: timeSlot.startAtUtc,
            localStartAt: dayjs(`${timeSlot.date} ${timeSlot.startTime}`).format(),
            endAt: timeSlot.endAtUtc,
            localEndAt: dayjs(`${timeSlot.date} ${timeSlot.endTime}`).format(),
          }
        })

      state.availability[calendarId][date.format(DEFAULT_DATE_FORMAT)] = slots;

      state.scanResults[calendarId][date.format(DEFAULT_DATE_FORMAT)] =
        slots?.length > 0 ?
          {
            date: slots[0].date,
            startTime: slots[0].startTime,
            endTime: slots[0].endTime,
            startAtUtc: slots[0].startAtUtc,
            endAtUtc: slots[0].endAtUtc,
          }
          : false;
    }
  })
}

function writeScanResultsToCache(state, action) {
  action.payload.data.calendars.forEach(calendar => {
    state.scanResults[calendar.calendarId] = state.scanResults[calendar.calendarId] ?? {};
    const terminalDate = calendar.firstAvailability?.date || action.meta.previousAction.payload.request.data.maxStartDate;
    for (let minDate = dayjs(action.meta.previousAction.payload.request.data.minStartDate); minDate.diff(terminalDate, 'd') <= 0; minDate = minDate.add(1, 'd')) {
      const hasAvailabilityThisDate = calendar.firstAvailability && dayjs(calendar.firstAvailability.date).diff(minDate.format(DEFAULT_DATE_FORMAT)) === 0;

      state.scanResults[calendar.calendarId][minDate.format(DEFAULT_DATE_FORMAT)] =
        hasAvailabilityThisDate ? calendar.firstAvailability : false;

    }
  })
}

function writeInFlightSearchToCache(state, action) {
  action.payload.request.data.calendarIds.forEach(calendarId => {
    state.inFlightSearchLog[calendarId] = state.inFlightSearchLog[calendarId] ?? {};
    generateDateArray(action.payload.request.data.minStartDate, action.payload.request.data.maxStartDate).forEach(date => {
      state.inFlightSearchLog[calendarId][date] = true
    })
  })
}

function writeInFlightScanToCache(state, action) {
  action.payload.request.data.calendarIds.forEach(calendarId => {
    state.inFlightScanLog[calendarId] = state.inFlightScanLog[calendarId] ?? {};
    generateDateArray(action.payload.request.data.minStartDate, action.payload.request.data.maxStartDate).forEach(date => {
      state.inFlightScanLog[calendarId][date] = true
    })
  })
}
//#endregion
