import { createPatientRequestedOrder } from "actions/orderManagementActions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tooltip } from "reactstrap";
import { CareOrderDetails, CareOrderTypeEnum } from "../types/CareOrderDetails";
import dayjs from "dayjs";
import { useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CareOrderPatientNotificationModal from "../../../components/modals/careOrderPatientNotificationModal"
import SessionFlowTypes from '../../../constants/sessionFlowTypes';
import "../css/saveSearchToCareOrder.css"

const SaveSearchToCareOrder = () => {

    const [isSavingSearch, setIsSavingSearch] = useState<boolean>(false);
    const [openCareOrderNotificationModal, setOpenCareOrderNotificationModal] = useState<boolean>(false);
    const [careOrderVisitReferenceId, setCareOrderVisitReferenceId] = useState<string>("")
    const [sendFailedTwice, setSendFailedTwice] = useState<boolean>(false);

    const dispatch = useDispatch();

    const payload = useSelector(selectSearchToCareOrderDetails);
    const hasBeenSaved = useSelector((state: any) => state.session.isDecisionSupportSessionSavedToCareOrder);
    const isDuplicateFlow = useSelector((state: any) => state.session.schedulingSessionFlowType === SessionFlowTypes.duplicateFlow);
    const patientDetails = useSelector((state: any) => state.activePatient.details);
    const patientNotificationConfig = useSelector((state: any) => state.config.notification);

    const handleSaveSearchToCareOrder = async () => {
        setIsSavingSearch(true);

        const response: any = await dispatch(createPatientRequestedOrder(payload));
        setCareOrderVisitReferenceId(response.payload.data);
        setIsSavingSearch(false);
    }

    const handleSendCareOrderToPatient = async () => {
        if (!hasBeenSaved && !isDuplicateFlow) {
            await handleSaveSearchToCareOrder();
        }
        setOpenCareOrderNotificationModal(!isSavingSearch);
    }

    const closeCareOrderModal = () => {
        setOpenCareOrderNotificationModal(false);
    }

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    const isSendAllowed = useMemo(() => {
        return (((patientNotificationConfig.enablePatientCareOrderEmail && (patientDetails?.email || patientNotificationConfig.allowPatientCareOrderOtherEmail))
            || (patientNotificationConfig.enablePatientCareOrderSms && (patientDetails?.mobilePhone || patientNotificationConfig.allowPatientCareOrderOtherPhone)))
            && !sendFailedTwice
        )
    }, [patientDetails, patientNotificationConfig, sendFailedTwice]);

    const buttonToolTipText = () => {
        if (!isSendAllowed) {
            return <p>This will create a new care order for this patient.</p>
        }
        else {
            return <p>
                Click here to send these results to patient.
                <br />
                Note: This will create a new care order for this patient.
            </p>
        }
    }

    return (
        <>
            <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target={!isSendAllowed ? "saveCareOrderButton" : "sendCareOrderButton"}
                toggle={toggle}
                id="saveOrSendCareOrderButtonToolTip"
            >
                {buttonToolTipText()}
            </Tooltip>
            {!isSendAllowed ?
                (
                    <Button
                        id="saveCareOrderButton"
                        color="primary"
                        className="mt-2"
                        onClick={handleSaveSearchToCareOrder}
                        disabled={isSavingSearch || hasBeenSaved}
                    >
                        {(hasBeenSaved || isDuplicateFlow)
                            ? "Saved"
                            : isSavingSearch
                                ? <><FontAwesomeIcon icon="spinner" spin /> Saving ...</>
                                : "Save Search"
                        }
                    </Button>
                ) : (
                    <Button
                        id="sendCareOrderButton"
                        color="primary"
                        className="mt-2"
                        onClick={handleSendCareOrderToPatient}>
                        {isSavingSearch
                            ? <><FontAwesomeIcon icon="spinner" spin /> Saving ...</>
                            : "Send To Patient"
                        }
                    </Button>
                )
            }
            {openCareOrderNotificationModal &&
                <CareOrderPatientNotificationModal
                    toggle={closeCareOrderModal}
                    careOrderExpirationDate={payload.expirationDate}
                    consumerIdProductInstance={payload.consumerIdProductInstance}
                    careOrderVisitReferenceId={careOrderVisitReferenceId}
                    setSendFailedTwice={setSendFailedTwice}
                    sendFailedTwice={sendFailedTwice}
                />
            }
        </>
    )
}

export default SaveSearchToCareOrder;

const selectSearchToCareOrderDetails = (state: any): CareOrderDetails => {

    return {
        id: 0,
        systemId: state.auth.referralSystemId,
        patientReferenceId: state.activePatient.id,
        userId: "00000000-0000-0000-0000-000000000000", // this is handled in api from token
        careOrderStatusReasonId: 5, // created
        idMhdD2cClientInstance: null,
        idProductInstance: state.auth.productInstanceId,
        careOrderType: CareOrderTypeEnum.PatientRequestedDecisionSupportOutput,
        decisionSupportSessionId: state.session.decisionSupportSessionId,
        expirationDate: dayjs().add(state.config.orderManagement.patientRequestedOrderExpirationLeadDays, 'days').toISOString(),
        currentFailedAuthCount: 0,
        lastFailedAuthAt: null,
        lockoutEndAt: null,
        visits: [
            {
                minVisitDate: dayjs().startOf('day').toISOString(), // these aren't nullable in the db
                maxVisitDate: dayjs('9999-12-31T23:59:59.999Z').toISOString(), // these aren't nullable in the db
                systemDateRangeId: null,
                referenceId: null,
                appointments: [
                    {
                        orderIndex: 1,
                        siteId: null,
                        serviceId: null,
                        appointmentTypeId: null,
                        specialtyId: null,
                        referringSiteId: null,
                        referringServiceId: null,
                        unmanagedReferringSiteName: "",
                        unmanagedReferringServiceName: "",
                        reasonForVisit: "",
                        notes: "",
                        externalReferralOrderId: "",
                        subgroupId: null,
                        customFields: []
                    }
                ]
            }
        ],
        customFields: [],
        consumerIdProductInstance: state.config.orderManagement.idProductInstanceConsumerCareOrder,
        externalReferralOrderId: "",
        sendNotificationsAfterCreate: false, // TODO **NEW CONFIG**
    };
}