/**
 * @format
 */

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Alert } from 'reactstrap';
import * as routes from '../../routes';
import * as availabilitySearchActions from '../../actions/availabilitySearchActions';
import * as appointmentActions from '../../actions/appointmentActions';
import * as rd2RefactorActions from '../../actions/rd2RefactorActions';
import RescheduleAppointmentDetailsOverview from '../../components/appointment/rescheduleAppointmentDetailsOverview';
import LoadingIndicator from '../../components/loadingIndicator';
import { PATIENT_DETAILS, APPOINTMENT_DETAILS, SAME_PROVIDER, DIFFERENT_PROVIDER } from 'constants/actionReferrer';
import { processDecisionSupportSubpoints } from '../../actions/decisionSupportActions';
import { fetchDecisionSupportSessionOutput } from "actions/sessionActions";

export const AppointmentRescheduleView = (props) => {
	const [appointmentDetails, setAppointmentDetails] = useState({});
	const [error, setError] = useState('');
	const [isDetailsLoading, setIsDetailsLoading] = useState(true);

	const { referenceId } = useParams(); // Extracting referenceId from the route params

	useEffect(() => {
		props.actions.appointment.getPreviousAppointmentByReferenceId(referenceId).then((response) => {
			if (response.error) {
				setError('An error occurred while trying to get appointment details.');
			} else {
				setAppointmentDetails(response.payload.data);
			}
			setIsDetailsLoading(false);
		});
	}, [props.actions.appointment, referenceId]);

	const generateSearchCriteria = (appointmentDetails) => {
		let minAvailabilityStartAt = moment().startOf('day').format();
		let crit = {
			assetTags: null,
			availabilityChunkSize: props.availabilitySearchConfig.chunkSize,
			availabilitySearchDays: props.availabilitySearchConfig.searchDays,
			correlationKey: props.correlationKey,
			idPgmAppointmentTypeList: [appointmentDetails.idPgmAppointmentType],
			// idPgmInsuranceProvider: appointmentDetails.idPgmInsuranceProvider, // keep these out of search for now per Micheal
			// idPgmPayorType: appointmentDetails.idPgmPayorType, // keep these out of search for now per Micheal
			idPgmServiceType: appointmentDetails.idPgmServiceType,
			idPgmSpecialty: appointmentDetails.idPgmSpecialty,
			minAvailabilityStartAt: minAvailabilityStartAt,
			maxAvailabilityStartAt: null,
			pageIndex: 1,
			pageSize: props.availabilitySearchConfig.defaultPageSize,
			patientEmail: props.activePatient.details.email,
			patientReferenceId: props.activePatient.details.referenceId,
			referralSiteId: props.referralSiteId,
			searchRadius: null,
			seekFirstAvailability: true,
			selectedProviderCalendarList: [appointmentDetails.idSelectedProviderCalendar],
			selectedProviderNpiList: [appointmentDetails.serviceNpi],
			selectedSiteIdList: [appointmentDetails.serviceSiteId],
			serviceNpiList: [appointmentDetails.serviceNpi],
			serviceSiteList: [appointmentDetails.serviceSiteId],
			siteIdList: [appointmentDetails.practiceSiteId],
			sortOrderPreference: 1,
			zipCode: null,
			latitude: props.activePatient.details.latitude,
			longitude: props.activePatient.details.longitude,
		};
		JSON.parse(JSON.stringify(crit));
		return crit;
	};

	const startRescheduleWithDifferentProvider = async () => {
		const { decisionSupportSessionId } = props.cancelRescheduleInfo.previousAppointmentDetails;
		if (decisionSupportSessionId) {
			await props.dispatch(fetchDecisionSupportSessionOutput(decisionSupportSessionId));

			props.dispatch(async (dispatch, getState) => {
				let state = getState();
				await dispatch(processDecisionSupportSubpoints(state.session.decisionSupportSubpoints, true));
				state = getState();
				await dispatch(
					rd2RefactorActions.startAvailabilitySearch(
						state.careOrder,
						state.decisionSupport,
						state.config.availabilitySearch,
						state.activePatient.details,
					),
				);
			});
		}
		else {
			startRescheduleWithDifferentProviderForPsa();
		}
	};

	const startRescheduleWithDifferentProviderForPsa = () => {
		props.actions.rd2RefactorActions.resetAvailabilitySearchState();
		props.actions.rd2RefactorActions.setPreviousAppointment(props.previousAppointmentReferenceId, DIFFERENT_PROVIDER);
		props.actions.rd2RefactorActions.startBookingProcess(
			props.activeCareOrder,
			props.decisionSupportOutput,
			props.availabilitySearchConfig,
			props.activePatient.details,
			props.decisionSupport.useDecisionSupport,
		);
	};

	const startRescheduleWithSameProvider = () => {
		let appointmentReferenceId = appointmentDetails.referenceId;
		props.actions.rd2RefactorActions.setPreviousAppointment(appointmentReferenceId, SAME_PROVIDER);
		let appointmentSearchDetails = props.cancelRescheduleInfo.previousAppointmentDetails;
		let searchCriteria = generateSearchCriteria(appointmentSearchDetails);
		props.actions.availabilitySearch.setRescheduleAvailabilitySearchState(
			searchCriteria,
			props.availabilitySearchConfig,
			props.activePatient.details,
		);
		props.dispatch(routes.availabilitySearch());
	};

	const bookAnotherAppointment = () => {
		if (props.cancelRescheduleInfo && props.cancelRescheduleInfo.previousAppointmentReferenceId) {
			props.actions.rd2RefactorActions.clearPreviousAppointment();
		}
		props.actions.rd2RefactorActions.clearAgentInstructions();
		props.actions.rd2RefactorActions.startBookingProcess(
			props.activeCareOrder,
			props.decisionSupportOutput,
			props.availabilitySearchConfig,
			props.activePatient.details,
			props.decisionSupport.useDecisionSupport,
		);
	};

	const goBack = () => {
		let actionReferrer = props.cancelRescheduleInfo.actionReferrer;
		if (actionReferrer === PATIENT_DETAILS) {
			props.actions.rd2RefactorActions.clearPreviousAppointment();
			props.dispatch(routes.patientDetails(props.activePatient.id));
		} else if (actionReferrer === APPOINTMENT_DETAILS) {
			props.actions.rd2RefactorActions.clearPreviousAppointment();
			props.dispatch(routes.appointmentDetails(props.previousAppointmentReferenceId));
		} else {
			props.actions.rd2RefactorActions.clearPreviousAppointment();
			bookAnotherAppointment();
		}
	};

	return (
		<div className="container-fluid">
			<div>{error && <Alert color="danger">{error}</Alert>}</div>
			{isDetailsLoading ? (
				<LoadingIndicator loadingMessage={'Loading. Please wait.'} />
			) : (
				<RescheduleAppointmentDetailsOverview
					rescheduleInfo={props.cancelRescheduleInfo}
					details={appointmentDetails}
					goBack={goBack}
					previousAppointmentDetails={props.cancelRescheduleInfo.previousAppointmentDetails}
					providerFieldConfig={props.providerFieldConfig}
					schedulingConfig={props.schedulingConfig}
					showInsuranceOnBookAppointment={props.schedulingConfig.showInsuranceOnBookAppointment}
					startRescheduleWithDifferentProvider={startRescheduleWithDifferentProvider}
					startRescheduleWithSameProvider={startRescheduleWithSameProvider}
					startRescheduleWithDifferentProviderForPsa={startRescheduleWithDifferentProviderForPsa}
				/>
			)}
		</div>
	);
};

function mapStateToProps(state, ownProps) {
	return {
		activeCareOrder: state.careOrder,
		activePatient: state.activePatient,
		availabilitySearchConfig: state.config.availabilitySearch,
		decisionSupport: state.config.decisionSupport,
		decisionSupportOutput: state.decisionSupport,
		patientEmail: state.activePatient.details.email,
		patientReferenceId: state.activePatient.details.referenceId,
		patientCancellationEmailDisclaimer: state.config.notification.patientCancellationEmailDisclaimer,
		previousAppointmentReferenceId: state.appointment.cancelRescheduleInfo.previousAppointmentReferenceId,
		providerFieldConfig: state.config.provider,
		referralSiteId: state.auth.referralSiteId,
		cancelRescheduleInfo: state.appointment.cancelRescheduleInfo,
		schedulingConfig: state.config.scheduling,
		systemCancelReasons: state.config.systemCancelReasons,
		decisionSupportSessionSubpoints: state.session.decisionSupportSubpoints,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			availabilitySearch: bindActionCreators(availabilitySearchActions, dispatch),
			appointment: bindActionCreators(appointmentActions, dispatch),
			rd2RefactorActions: bindActionCreators(rd2RefactorActions, dispatch),
		},
		dispatch,
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentRescheduleView);
