import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';

import { getAppointmentReferenceId } from '../../actions/appointmentActions';
import { appointmentDetails as routeToAppointmentDetails } from '../../routes';
import NotFound from '../../components/NotFound';
import LoadingIndicator from '../../components/loadingIndicator';

export default function AppointmentRedirector() {
	const dispatch = useDispatch();
    const location = useLocation();

    const { refValue } = useParams();

    const [isLoading, setLoading] = useState(true);
    const config = useSelector((state) => state.config);

    const appointmentConfig = config.appointmentManagement;
    const queryStringParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    useEffect(() => {
        if (appointmentConfig.allowExternalAppointmentDetailsUrl) {
            if (queryStringParams.refType && refValue) {
                dispatch(getAppointmentReferenceId(queryStringParams.refType, refValue)).then((response) => {
                    setLoading(false);
                    if (response.payload.data !== '') {
                        dispatch(routeToAppointmentDetails(response.payload.data));
                    }
                });
            } else {
                setLoading(false);
                if (refValue) {
                    dispatch(routeToAppointmentDetails(refValue));
                }
            }
        } else {
            setLoading(false);
        }
	}, [dispatch, config.appointmentManagement, refValue]);

	return isLoading ? <LoadingIndicator /> : <NotFound />;
}
