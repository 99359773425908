import React, { useRef } from 'react';
import { IHeaderParams } from 'ag-grid-community';

interface CustomHeaderSelectAllProps extends IHeaderParams {
	onSelectAllChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;
	isSelectAllChecked: boolean;
	hasSelectedRows: boolean;
}

export const CustomHeaderSelectAll: React.FC<React.PropsWithChildren<CustomHeaderSelectAllProps>> = (props) => {
	const { column, onSelectAllChecked, isSelectAllChecked = false, hasSelectedRows = false } = props;
	const checkboxRef = useRef<HTMLInputElement>(null);

	React.useEffect(() => {
		if (checkboxRef.current) {
			checkboxRef.current.checked = isSelectAllChecked;
			checkboxRef.current.indeterminate = hasSelectedRows;
		}
	}, [hasSelectedRows, isSelectAllChecked]);

	return (
		<div>
			<input ref={checkboxRef} type="checkbox" checked={isSelectAllChecked} onChange={onSelectAllChecked} />
			{column.getColDef().headerName}
		</div>
	);
};
