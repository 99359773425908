import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as routes from '../../../routes';
import { DataGrid } from "components/dataGrid/dataGrid";
import { AppointmentHistoryFilters } from "./patientAppointmentHistory";
import dayjs from "dayjs";
import moment from "moment";
import { clearSessionQuestionsAndAnswers } from "actions/guidedResponseActions";
import { BookAnotherAppointmentModal, ConfirmOrCancelModal } from "components/common/standardComponents";
import { setCancelRescheduleActionReferrer } from "actions/rd2RefactorActions";
import { fetchDecisionSupportSessionOutput } from "actions/sessionActions";
import { PATIENT_DETAILS } from "constants/actionReferrer";
import { deepCopy } from "nighthawk/utility/miscUtils";
import { useLocation } from "react-router";

const DATE_FORMAT = 'MM/DD/YY hh:mm A';

enum AppointmentActionLinkIds {
    cancelLink = "cancelLink",
    rescheduleLink = "rescheduleLink",
    bookAnotherLink = "bookAnotherLink",
}

const PatientAppointmentHistoryGrid = (
    {
        appointmentHistoryRecords,
        filters,
        showLoseProgressWarning,
        activeTab
    }: {
        appointmentHistoryRecords: any,
        filters: AppointmentHistoryFilters,
        showLoseProgressWarning: boolean,
        activeTab: any
    }
) => {

    const dispatch = useDispatch();
    const location = useLocation();

    const schedulingConfig = useSelector((state: any) => state.config.scheduling);
    const isBookingDisabled = useSelector((state: any) => state.activePatient.details.isBookingDisabled);
    const decisionSupportSessionSubpoints = useSelector((state: any) => state.session.decisionSupportSubpoints);

    const [historyRecordPendingAction, setHistoryRecordPendingAction] = useState<any>(null);
    const [showConfirmGoToAppointmentDetailsModal, setShowConfirmGoToAppointmentDetailsModal] = useState(false);
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
    const [showConfirmRescheduleModal, setShowConfirmRescheduleModal] = useState(false);
    const [showConfirmBookAnotherModal, setShowConfirmBookAnotherModal] = useState(false);
    const [showBookAnotherOptionsModal, setShowBookAnotherOptionsModal] = useState(false);
    const [isLoadingDssData, setIsLoadingDssData] = useState(false);
    const [isHandlingBookAnother, setIsHandlingBookAnother] = useState(false);

    const calcHasAgedOut = (rowData: any) => {
        var retVal = false;

        if (rowData && rowData.flowEndTime) {
            var startTime = moment(rowData.flowEndTime);
            var endTime = moment.utc();
            var days = Math.abs(startTime.diff(endTime, 'days', true));
            retVal = days > schedulingConfig.bookAnotherWithSameDecisionSupportMaxAgeInDays
        }

        return retVal;
    }

    const [gridKey, setGridKey] = useState(`grid-key-${Math.random()}`);
    useEffect(() => {
        if (activeTab?.reloadTabContent) {
            setGridKey(`grid-key-${Math.random()}`);
        }
    }, [activeTab])

    const isBookingAnotherEnabled = (rowData: any) => {
        var retVal = schedulingConfig.enableBookAnotherWithSameDecisionSupport || schedulingConfig.enableBookAnotherWithSameDecisionSupportAndProvider;

        if (retVal && rowData) {
            if (rowData.cancellationDateTime || !rowData.flowEndTime) return false;
            if (rowData.DecisionSupportTypeCode && rowData.DecisionSupportTypeCode !== 'GR') return false;
            retVal = !calcHasAgedOut(rowData);
        }

        return retVal;
    }

    const renderActionText = (rowData: any) => {
        const cancelLink = <span>
            <a
                id={AppointmentActionLinkIds.cancelLink}
                href='#Cancel'
                onClick={(e) => {
                    e.preventDefault();
                    if (showLoseProgressWarning) {
                        setHistoryRecordPendingAction(rowData);
                        setShowConfirmCancelModal(true);
                    } else {
                        handleCancelAppointment(rowData, dispatch);
                    }
                }}
            >
                Cancel
            </a>
        </span>;

        const rescheduleLink = <span>
            <a
                id={AppointmentActionLinkIds.rescheduleLink}
                href='#Reschedule'
                onClick={(e) => {
                    e.preventDefault();
                    if (showLoseProgressWarning) {
                        setHistoryRecordPendingAction(rowData);
                        setShowConfirmRescheduleModal(true);
                    } else {
                        handleRescheduleAppointment(rowData, dispatch);
                    }
                }}
            >
                Reschedule
            </a>
        </span>;

        const bookAnotherLink = <span>
            <a
                id={AppointmentActionLinkIds.bookAnotherLink}
                href='#BookAnother'
                onClick={(e) => {
                    e.preventDefault();
                    setHistoryRecordPendingAction(rowData);
                    if (showLoseProgressWarning) {
                        setShowConfirmBookAnotherModal(true);
                    } else {
                        handleBookAnotherAppointmentClick(rowData, dispatch, setShowBookAnotherOptionsModal, setIsLoadingDssData);
                    }
                }}>
                Book Another
            </a>
        </span>;

        const separator = <span> | </span>;

        if (rowData.isCancelledAppointment) {
            return (<span>Canceled</span>);
        } else if (rowData.isFutureAppointment && rowData.allowCancel) {
            if (rowData.allowReschedule && !isBookingDisabled) {
                if (isBookingAnotherEnabled(rowData)) {
                    return (<>{cancelLink}{separator}{rescheduleLink}{separator}{bookAnotherLink}</>);
                } else {
                    return (<>{cancelLink}{separator}{rescheduleLink}</>);
                }
            } else if (rowData.allowReschedule && isBookingDisabled) {
                return (<>{cancelLink}</>);
            } else if (!rowData.allowReschedule && !isBookingDisabled) {
                if (isBookingAnotherEnabled(rowData)) {
                    return (<>{cancelLink}{separator}{bookAnotherLink}</>);
                } else {
                    return (<>{cancelLink}</>);
                }
            } else if (!rowData.allowReschedule && isBookingDisabled) {
                return (<>{cancelLink}</>);
            }
        } else if (rowData.isFutureAppointment && !rowData.allowCancel) {
            if (rowData.allowReschedule && !isBookingDisabled) {
                if (isBookingAnotherEnabled(rowData)) {
                    return (<>{rescheduleLink}{separator}{bookAnotherLink}</>);
                } else {
                    return (<>{rescheduleLink}</>);
                }
            } else if (!rowData.allowReschedule && !isBookingDisabled) {
                if (isBookingAnotherEnabled(rowData)) {
                    return (<>{bookAnotherLink}</>);
                }
                else {
                    return (<span>Not Available</span>);
                }
            }
        } else if (!rowData.isFutureAppointment && !isBookingDisabled && isBookingAnotherEnabled(rowData)) {
            return (<>{bookAnotherLink}</>);
        } else {
            return (<span>Not Available</span>);
        }
    }

    const columnDefs = [
        {
            field: 'createDateTime',
            headerName: 'Created Date',
            suppressSizeToFit: true,
            cellRenderer: (r: any) => <span>{r.data.createDateTime ? dayjs(r.data.createDateTime).format(DATE_FORMAT) + ` ${r.data.createDateTimeZoneString}` : '-'}</span>
        },
        {
            field: 'appointmentDate',
            headerName: 'Appointment Date',
            suppressSizeToFit: true,
            cellRenderer: (r: any) => <span>{r.data.appointmentDate ? dayjs(r.data.appointmentDate).format(DATE_FORMAT) + ` ${r.data.appointmentDateTimeZoneString}` : '-'}</span>
        },
        {
            field: 'providerName',
            headerName: 'Provider',
            suppressSizeToFit: true,
            cellRenderer: (r: any) => <span>{r.data.providerName ? r.data.providerName.trim() : '-'}</span>
        },
        {
            field: 'specialty',
            headerName: 'Specialty',
            suppressSizeToFit: true,
            cellRenderer: (r: any) => <span>{r.data.specialty ? r.data.specialty.trim() : '-'}</span>
        },
        {
            field: 'practiceLocation',
            headerName: 'Location',
            suppressSizeToFit: true,
            cellRenderer: (r: any) => <span>{r.data.practiceLocation ? r.data.practiceLocation.trim() : '-'}</span>
        },
        {
            field: 'patientAttendance',
            headerName: 'Attendance',
            suppressSizeToFit: true,
            cellRenderer: (r: any) => <span>{r.data.patientAttendance ? r.data.patientAttendance.trim() : 'Not Reported'}</span>
        },
        {
            field: 'reason4Referral',
            headerName: 'Reason For Visit',
            suppressSizeToFit: true,
            hide: !schedulingConfig.showReasonForVisitPatientHistory,
            cellRenderer: (r: any) => <span>{r.data.reason4Referral ? r.data.reason4Referral.trim() : '-'}</span>
        },
        {
            field: 'providerResponse',
            headerName: 'Provider Response',
            suppressSizeToFit: true,
            hide: !schedulingConfig.showProviderResponsePatientHistory,
            cellRenderer: (r: any) => <span>{r.data.providerResponse ? r.data.providerResponse.trim() : '-'}</span>
        },
        {
            headerName: 'Actions',
            sortable: false,
            suppressSizeToFit: true,
            width: 250,
            cellRenderer: (r: any) => { return (<span className='historyText'>{renderActionText(r.data)}</span>); }
        },
        {
            field: 'cancelReason',
            headerName: 'Cancel Reason',
            suppressSizeToFit: true,
            hide: !(schedulingConfig.showReasonForCancellationPatientHistory && filters.includeCanceled)
        }
    ];

    const appointmentHistoryFilteredAndMapped = appointmentHistoryRecords
        .filter((historyRecord: any) => {
            if (filters.hideMHD) {
                return historyRecord.scheduleMethod !== 'MHD'
            }
            else if(filters.hidePSA) {
                return historyRecord.scheduleMethod !== 'PSA'
            }
            else {
                return true;
            }
        })
        .filter((historyRecord: any) => {
            if (filters.includeCanceled && historyRecord.cancellationDateTime !== null) {
                return true;
            } else {
                return historyRecord.cancellationDateTime === null;
            }
        })
        .map((historyRecord: any) => {
            const isPsaAppointment = Boolean(historyRecord.scheduleMethod === "PSA");
            return {
                ...historyRecord,
                isCancelledAppointment: Boolean(historyRecord.cancellationDateTime),
                isFutureAppointment: moment.utc(historyRecord.appointmentDateUtc).isAfter(),
                allowReschedule: !isPsaAppointment && schedulingConfig.allowMHDReschedules ? true : isPsaAppointment && schedulingConfig.allowPSAReschedules ? true : false,
                allowCancel: !isPsaAppointment && schedulingConfig.allowMHDCancellations ? true : isPsaAppointment && schedulingConfig.allowPSACancellations ? true : false,
                cancelReason: historyRecord.cancelReason ? historyRecord.cancelReason : '-'
            }
        })

    return (
        <>
            <div>
                <DataGrid
                    autoHeight={true}
                    columnDefs={columnDefs}
                    data={appointmentHistoryFilteredAndMapped}
                    getRowClass={(r: any) => r.data.isCancelledAppointment ? 'cancelled-row' : ""}
                    suppressRowClickSelection={false}
                    onRowClick={(e: any, historyRecord: any) => {
                        e.preventDefault();
                        if (Object.values(AppointmentActionLinkIds).includes(e.srcElement.id)) {
                            return;
                        }

                        if (showLoseProgressWarning) {
                            setHistoryRecordPendingAction(historyRecord);
                            setShowConfirmGoToAppointmentDetailsModal(true);
                        } else {
                            handleGoToAppointmentDetails(historyRecord, dispatch);
                        }
                    }}
                    noRowsMessage="No history records found."

                    customGridSearch={undefined}
                    gridHeading={undefined}
                    gridKey={gridKey}
                    getRowHeight={undefined}
                    defaultPageSize={undefined}
                    editable={undefined}
                    enableColumnFilters={undefined}
                    setSelectedRows={undefined}
                    serverSideDatasource={undefined}
                />
            </div>
            {showConfirmGoToAppointmentDetailsModal &&
                <ConfirmGoToAppointmentDetailsModal
                    historyRecord={historyRecordPendingAction}
                    setHistoryRecordPendingAction={setHistoryRecordPendingAction}
                    setShowConfirmGoToAppointmentDetailsModal={setShowConfirmGoToAppointmentDetailsModal}
                />
            }
            {showConfirmCancelModal &&
                <ConfirmCancelAppointmentModal
                    historyRecord={historyRecordPendingAction}
                    setHistoryRecordPendingAction={setHistoryRecordPendingAction}
                    setShowConfirmCancelModal={setShowConfirmCancelModal}
                />
            }
            {showConfirmRescheduleModal &&
                <ConfirmRescheduleAppointmentModal
                    historyRecord={historyRecordPendingAction}
                    setHistoryRecordPendingAction={setHistoryRecordPendingAction}
                    setShowConfirmRescheduleModal={setShowConfirmRescheduleModal}
                />
            }
            {showConfirmBookAnotherModal &&
                <ConfirmBookAnotherModal
                    historyRecord={historyRecordPendingAction}
                    setHistoryRecordPendingAction={setHistoryRecordPendingAction}
                    setShowConfirmBookAnotherModal={setShowConfirmBookAnotherModal}
                    setShowBookAnotherOptionsModal={setShowBookAnotherOptionsModal}
                    setIsLoadingDssData={setIsLoadingDssData}
                />
            }
            {showBookAnotherOptionsModal &&
                <BookAnotherAppointmentModal
                    config={schedulingConfig}
                    decisionSupportSessionSubpoints={decisionSupportSessionSubpoints}
                    deepCopy={deepCopy}
                    dispatch={dispatch}
                    isLoading={isLoadingDssData || isHandlingBookAnother}
                    isOpen={true}
                    historyRecordPendingAction={historyRecordPendingAction}
                    location={location}
                    setShowBookAnotherOptionsModal={setShowBookAnotherOptionsModal}
                    setIsHandlingBookAnother={setIsHandlingBookAnother}
                    setHistoryRecordPendingAction={setHistoryRecordPendingAction}
                />
            }
        </>
    )
}

export default PatientAppointmentHistoryGrid;

const handleGoToAppointmentDetails = (historyRecord: any, dispatch: any) => {
    if (historyRecord?.cancellationDateTime) {
        if (!historyRecord?.decisionSupportSessionId) {
            dispatch(clearSessionQuestionsAndAnswers());
        }
        dispatch(routes.cancelledAppointmentDetails(historyRecord.appointmentReferenceId));
    } else {
        if (!historyRecord?.decisionSupportSessionId) {
            dispatch(clearSessionQuestionsAndAnswers());
        }
        dispatch(routes.appointmentDetails(historyRecord.appointmentReferenceId));
    }
}

const ConfirmGoToAppointmentDetailsModal = (
    {
        historyRecord,
        setShowConfirmGoToAppointmentDetailsModal,
        setHistoryRecordPendingAction
    }: {
        historyRecord: any,
        setShowConfirmGoToAppointmentDetailsModal: any,
        setHistoryRecordPendingAction: any,
    }
) => {
    const dispatch = useDispatch();
    return (
        <InternalConfirmOrCancelModal
            onConfirm={() => {
                handleGoToAppointmentDetails(historyRecord, dispatch);
            }}
            onCancel={() => {
                setHistoryRecordPendingAction(null);
                setShowConfirmGoToAppointmentDetailsModal(false);
            }}
        />
    )
}

const handleCancelAppointment = (historyRecord: any, dispatch: any) => {
    dispatch(setCancelRescheduleActionReferrer(PATIENT_DETAILS));
    dispatch(routes.cancelAppointment(historyRecord.appointmentReferenceId));
}

const ConfirmCancelAppointmentModal = (
    {
        historyRecord,
        setShowConfirmCancelModal,
        setHistoryRecordPendingAction
    }: {
        historyRecord: any,
        setShowConfirmCancelModal: any,
        setHistoryRecordPendingAction: any,
    }
) => {
    const dispatch = useDispatch();
    return (
        <InternalConfirmOrCancelModal
            onConfirm={() => {
                handleCancelAppointment(historyRecord, dispatch);
            }}
            onCancel={() => {
                setHistoryRecordPendingAction(null);
                setShowConfirmCancelModal(false);
            }}
        />
    )
}

const handleRescheduleAppointment = (historyRecord: any, dispatch: any) => {
    dispatch(setCancelRescheduleActionReferrer(PATIENT_DETAILS));
    dispatch(routes.rescheduleAppointment(historyRecord.appointmentReferenceId));
}

const ConfirmRescheduleAppointmentModal = (
    {
        historyRecord,
        setShowConfirmRescheduleModal,
        setHistoryRecordPendingAction
    }: {
        historyRecord: any,
        setShowConfirmRescheduleModal: any,
        setHistoryRecordPendingAction: any,
    }
) => {
    const dispatch = useDispatch();
    return (
        <InternalConfirmOrCancelModal
            onConfirm={() => {
                handleRescheduleAppointment(historyRecord, dispatch);
            }}
            onCancel={() => {
                setHistoryRecordPendingAction(null);
                setShowConfirmRescheduleModal(false);
            }}
        />
    )
}

const handleBookAnotherAppointmentClick = (historyRecord: any, dispatch: any, setShowBookAnotherOptionsModal: any, setIsLoadingDssData: any) => {
    setShowBookAnotherOptionsModal(true);
    setIsLoadingDssData(true);
    dispatch(fetchDecisionSupportSessionOutput(historyRecord.decisionSupportSessionId)).then(() => {
        setIsLoadingDssData(false);
    });
}

const ConfirmBookAnotherModal = (
    {
        historyRecord,
        setShowConfirmBookAnotherModal,
        setHistoryRecordPendingAction,
        setShowBookAnotherOptionsModal,
        setIsLoadingDssData,
    }: {
        historyRecord: any,
        setShowConfirmBookAnotherModal: any,
        setHistoryRecordPendingAction: any,
        setShowBookAnotherOptionsModal: any,
        setIsLoadingDssData: any,
    }
) => {
    const dispatch = useDispatch();

    return (
        <InternalConfirmOrCancelModal
            onConfirm={() => {
                setShowConfirmBookAnotherModal(false);
                handleBookAnotherAppointmentClick(historyRecord, dispatch, setShowBookAnotherOptionsModal, setIsLoadingDssData);
            }}
            onCancel={() => {
                setHistoryRecordPendingAction(null);
                setShowConfirmBookAnotherModal(false);
            }}
        />
    )
}

export const InternalConfirmOrCancelModal = (
    {
        onConfirm,
        onCancel,
    }: {
        onConfirm: Function,
        onCancel: Function,
    }
) => {
    return (
        <ConfirmOrCancelModal
            className={"confirmModal"}
            title={"Confirm"}
            body={"If you continue you will lose your current progress. Are you sure you want to leave?"}
            isOpen={true}
            confirmLabel={"Confirm"}
            cancelLabel={"Cancel"}
            onConfirm={onConfirm}
            onCancel={onCancel}
        />
    )
}