import { useState } from "react";
import UserViewContainer from "./comps/userViewContainer"
import { useDispatch } from "react-redux";
import { useAppSelector } from 'store';
import { forgotPassword, checkUserProductInstance } from "actions/userActions";
import { login } from "../../routes";
import ForgotPasswordForm from "./comps/forgotPasswordForm";
import LoginLogo from "./comps/loginLogo";
import LoginFooter from "./comps/loginFooter";
import ContactUs from "./comps/contactUs";

const ForgotPasswordView = () => {

    const dispatch = useDispatch();

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const instanceId: any = useAppSelector((state: any) => state.auth.productInstanceId);

    const sendResetEmail = async (email: string) => {
        setIsSaving(true);
        try {
            const userProductInstance: any = await dispatch(checkUserProductInstance(email));
            if (userProductInstance.type === 'USER_PRODUCT_INSTANCE_SUCCESS' && userProductInstance.payload?.data === instanceId) {
                const response: any = await dispatch(forgotPassword(email));
                setIsSaving(false);
                if (response.error) {
                    setShowError(true);
                    setShowSuccess(false);
                } else {
                    setShowError(false);
                    setShowSuccess(true);
                }
            }
            else{
                setIsSaving(false);
                setShowError(false);
                setShowSuccess(true);
            }
        }
        catch {
                setIsSaving(false);
                setShowError(true);
                setShowSuccess(false);
            }
    }

    const goToLogin = () => {
        dispatch(login());
    }

    return (
        <UserViewContainer
            isLoading={false}
            className="forgotPasswordView"
        >
            <div className="forgotPasswordWindow">
                <LoginLogo />
                <ForgotPasswordForm
                    onSubmit={sendResetEmail}
                    onCancel={goToLogin}
                    showSuccess={showSuccess}
                    showError={showError}
                    isSaving={isSaving}
                />
                <LoginFooter />
                <ContactUs />
            </ div>
        </UserViewContainer>
    )
}

export default ForgotPasswordView;