/**
 * @format
 */

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { YesNoModal } from '../../components/common/standardComponents';
import * as routes from '../../routes';
import { processDecisionSupportSubpoints } from '../../actions/decisionSupportActions';
import { startAvailabilitySearch, startPatientSearchProcess, } from '../../actions/rd2RefactorActions';
import AppliedPathwaysComponent from '../../components/appliedPathways/appliedPathwaysComponent';
import GuidedResponseComponent from '../../components/guidedResponse/guidedResponseComponent';
// import PatientDetailsHeader from '../../components/patient/patientDetailsHeader';
import { DEFAULT_BACK_BUTTON_WARNING_DECISION_SUPPORT_VIEW } from '../../constants/miscStrings';
import PatientInfoTabbedCollapsable from 'features/patient/comps/patientInfoTabbedCollapsable';
import * as types from '../../actions/actionTypes';

export class DecisionSupportView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isBackButtonModalOpen: false,
			isPatientHeaderCollapsed: true,
			chainGuidedResponseFlowFromAp: false,
			chainedFlowUniqueToken: null,
			subpointsFromAppliedPathways: null,
		};
	}

	setBrowserHistoryState = () => {
		window.history.pushState(null, null, window.location.pathname);
	};

	createBackButtonModalEventListener = () => {
		window.addEventListener('popstate', this.onBrowserBackButtonClick);
	};

	componentDidMount() {
		this.setBrowserHistoryState();
		this.createBackButtonModalEventListener();

		if (
			this.props.config.patient.notes.isVisible &&
			this.props.config.scheduling.autoExpandPatientHeaderForVisibleNotes
		) {
			this.setState({ isPatientHeaderCollapsed: false });
		}
	}

	removeBackButtonModalEventListener = () => {
		window.removeEventListener('popstate', this.onBrowserBackButtonClick);
	};

	componentWillUnmount() {
		this.removeBackButtonModalEventListener();
	}

	onBrowserBackButtonClick = (e) => {
		e.preventDefault();
		this.setState({ isBackButtonModalOpen: true });
	};

	handleCompleted = (subpoints, endedInGuidedResponse) => {
		this.props.dispatch(processDecisionSupportSubpoints(subpoints, endedInGuidedResponse)).then((data) => {
			this.props.dispatch(
				startAvailabilitySearch(
					data.activeCareOrder,
					data.decisionSupportOutput,
					data.availabilitySearchConfig,
					data.patientDetails,
				),
			);
		});
	};

	onChainFlow = (subpoints) => {
		let chainedFlowUniqueToken = subpoints.find((subpoint) => subpoint.key === 'chainedflowuniquetoken').value;
		this.setState({
			chainGuidedResponseFlowFromAp: true,
			chainedFlowUniqueToken: chainedFlowUniqueToken,
			subpointsFromAppliedPathways: subpoints,
			enableDebuggerFromAp: this.props.decisionSupportConfig.enableFlowTesting
		});
	};

	handleAppliedPathwaysFlowComplete = (results) => {
		this.handleCompleted(results, false);
	};

	handleGuidedResponseFlowComplete = (results) => {
		this.handleCompleted(results, true);
	};

	buildAppliedPathwaysInputData = (patient) => {
		return { patient: patient };
	};

	buildGuidedResponseInputData = (patient, activeCareOrderDetails) => {
		var fields = [];

		this.mapPatientToGuidedResponseInputFields(fields, patient);
		this.mapCareOrderToGuidedResponseInputFields(fields, activeCareOrderDetails);

		if (this.state.subpointsFromAppliedPathways != null)
			this.mapAppliedPathwaysSubpointsToGuidedResponseInputs(fields, this.state.subpointsFromAppliedPathways);

		return { inputFields: fields };
	};

	mapPatientToGuidedResponseInputFields = (fields, patient) => {
		if (patient) {
			this.addOrOverrideFieldIfPresent(fields, 'PatientReferenceId', 'String', patient.referenceId);
			this.addOrOverrideFieldIfPresent(fields, 'PatientMemberId', 'String', patient.memberId);
			this.addOrOverrideFieldIfPresent(fields, 'PatientGroupNumber', 'String', patient.groupNumber);
			this.addOrOverrideFieldIfPresent(fields, 'PatientFirstName', 'String', patient.firstName);
			this.addOrOverrideFieldIfPresent(fields, 'PatientMiddleName', 'String', patient.middleName);
			this.addOrOverrideFieldIfPresent(fields, 'PatientLastName', 'String', patient.lastName);
			this.addOrOverrideFieldIfPresent(fields, 'PatientDateOfBirth', 'Date', this.getDateString(patient.dateOfBirth));
			this.addOrOverrideFieldIfPresent(fields, 'PatientGenderCode', 'String', patient.gender);
			this.addOrOverrideFieldIfPresent(fields, 'PatientGuardianName', 'String', patient.guardianName);
			this.addOrOverrideFieldIfPresent(fields, 'PatientEmail', 'String', patient.email);
			this.addOrOverrideFieldIfPresent(fields, 'PatientHomePhone', 'String', patient.homePhone);
			this.addOrOverrideFieldIfPresent(fields, 'PatientMobilePhone', 'String', patient.mobilePhone);
			this.addOrOverrideFieldIfPresent(fields, 'PatientAlternatePhone', 'String', patient.alternatePhone);
			this.addOrOverrideFieldIfPresent(fields, 'PatientAddressLine1', 'String', patient.addressLine1);
			this.addOrOverrideFieldIfPresent(fields, 'PatientAddressLine2', 'String', patient.addressLine2);
			this.addOrOverrideFieldIfPresent(fields, 'PatientCityName', 'String', patient.cityName);
			this.addOrOverrideFieldIfPresent(fields, 'PatientStateCode', 'String', patient.stateCode);
			this.addOrOverrideFieldIfPresent(fields, 'PatientZipCode', 'String', patient.zipCode);
			this.addOrOverrideFieldIfPresent(fields, 'PatientLatitude', 'String', patient.latitude);
			this.addOrOverrideFieldIfPresent(fields, 'PatientLongitude', 'String', patient.longitude);
			this.addOrOverrideFieldIfPresent(fields, 'PatientPoolPrefix', 'String', patient.patientPoolPrefix);
		}
	};

	mapCareOrderToGuidedResponseInputFields = (fields, activeCareOrderDetails) => {
		if (activeCareOrderDetails) {
			if (!fields.find((f) => f.fieldName === 'PatientZipCode')) {
				this.addOrOverrideFieldIfPresent(fields, 'PatientZipCode', 'String', activeCareOrderDetails.patientZip);
			}

			this.addOrOverrideFieldIfPresent(
				fields,
				'MaxVisitDate',
				'Date',
				this.getDateString(activeCareOrderDetails.maxVisitDate),
			);
			this.addOrOverrideFieldIfPresent(
				fields,
				'MinVisitDate',
				'Date',
				this.getDateString(activeCareOrderDetails.minVisitDate),
			);
			this.addOrOverrideFieldIfPresent(
				fields,
				'OrderExpirationDate',
				'Date',
				this.getDateString(activeCareOrderDetails.expirationDate),
			);

			if (activeCareOrderDetails.appointments && activeCareOrderDetails.appointments.length === 1) {
				let appointment = activeCareOrderDetails.appointments[0];

				this.addOrOverrideFieldIfPresent(fields, 'AppointmentTypeId', 'String', appointment.appointmentTypeId);
				this.addOrOverrideFieldIfPresent(fields, 'AppointmentTypeName', 'String', appointment.appointmentTypeName);

				//currently NPI isn't passed via careorders/appt BUT if it ever is then we have a hook for it.
				this.addOrOverrideFieldIfPresent(fields, 'NPI', 'String', appointment.npi);

				this.addOrOverrideFieldIfPresent(fields, 'ServiceTypeId', 'String', appointment.serviceTypeId);
				this.addOrOverrideFieldIfPresent(fields, 'ServiceId', 'String', appointment.serviceId);
				this.addOrOverrideFieldIfPresent(fields, 'SpecialtyId', 'String', appointment.specialtyId);
				this.addOrOverrideFieldIfPresent(fields, 'SiteId', 'String', appointment.siteId);
				this.addOrOverrideFieldIfPresent(
					fields,
					'ExternalReferralOrderNumber',
					'String',
					appointment.externalReferralOrderId,
				);

				if (appointment.careOrderCustomField !== null && appointment.careOrderCustomField.length) {
					appointment.careOrderCustomField.forEach((x) => {
						this.addOrOverrideFieldIfPresent(fields, `${x.fieldName}`, 'String', `${x.fieldValue}`);
					});
				}
			}
		}
	};

	mapAppliedPathwaysSubpointsToGuidedResponseInputs = (fields, subpoints) => {
		if (subpoints === null || subpoints === undefined) return;

		subpoints.forEach((subpoint) => {
			//TODO: Because I don't know what subpoints they may or may not send, I can't interrogate
			//the subpoints to set the inputType correctly going into GuidedResponse.
			//As a result I'm going to send them in as strings.
			if (subpoint.key.toLowerCase() === "patientdateofbirth") {
				//since patientdateofbirth is a standard input, we want to pass this as its actual type since
				//we know it will be present from some AP flows.
				this.addOrOverrideFieldIfPresent(fields, subpoint.key, 'Date', this.getDateString(subpoint.value));
			} else {
				this.addOrOverrideFieldIfPresent(fields, subpoint.key, 'String', subpoint.value);
			}
		});
	};

	getDateString = (date) => {
		if (date !== null && date !== undefined) {
			const d = moment(date).format('YYYY-MM-DD');
			return d;
		}
	};

	//TODO: move addFieldIfPresent and createField to a GuidedResponseHelpers file?
	addOrOverrideFieldIfPresent = (fields, fieldName, valueType, currentValue) => {
		// Note: The "if present" refers the presence of a currentValue to assign, not a field in the list
		if (currentValue) {
			let field = fields.find((f) => f.fieldName.toLowerCase() === fieldName.toLowerCase());
			if (field === undefined) {
				field = this.createField(fieldName, valueType, currentValue);
				fields.push(field);
			} else {
				// Code assumes value types agree, else we have a bug
				field.currentValue = currentValue;
			}
		}
	};

	createField = (fieldName, valueType, currentValue) => {
		var field = {
			fieldName: fieldName,
			valueType: valueType,
			currentValue: currentValue,
		};
		return field;
	};

	patientHeaderToggle = () => {
		this.setState({ isPatientHeaderCollapsed: !this.state.isPatientHeaderCollapsed });
	};

	redirectToPatientSearch = () => {
		this.props.dispatch(startPatientSearchProcess());
	};

	goBackToAppliedPathways = () => {
		this.setState({
			chainGuidedResponseFlowFromAp: false
		});
	}

	render() {
		// force redirect to patient search if no patient in context
		if (!this.props.patient.referenceId || this.props.patient.referenceId === '') {
			this.redirectToPatientSearch();
			return null;
		}

		// force redirect to availability search if decision support is not enabled
		if (!this.props.decisionSupportConfig.useDecisionSupport) {
			this.props.history.push(routes.availabilitySearchUrl());
			return null;
		}
		let useGuidedResponseFlow =
			this.state.chainGuidedResponseFlowFromAp || this.props.decisionSupportConfig.useGuidedResponse || this.props.flowSessionResponse?.flowSessionId.length;

		let inputData = useGuidedResponseFlow
			? this.buildGuidedResponseInputData(this.props.patient, this.props.activeCareOrder.activeCareOrderDetails)
			: this.buildAppliedPathwaysInputData(this.props.patient);

		let showPatientHeader = this.props.activePatient.details?.referenceId !== '' ? true : false;

		return (
			<>
				<div>
					<YesNoModal
						isOpen={this.state.isBackButtonModalOpen}
						body={DEFAULT_BACK_BUTTON_WARNING_DECISION_SUPPORT_VIEW}
						noLabel="No"
						yesLabel="Yes"
						title="Are you sure you want to go back?"
						onNo={() => {
							this.setBrowserHistoryState();
							this.setState({ isBackButtonModalOpen: false });
						}}
						onYes={() => {
							this.setState({ isBackButtonModalOpen: false, isLoading: true });
							this.redirectToPatientSearch();
						}}
					/>
				</div>
				{showPatientHeader ? (
					<div>
						<div className="row">
							<div className="col">
								<PatientInfoTabbedCollapsable
									isOpen={!this.state.isPatientHeaderCollapsed}
									toggleIsOpen={this.patientHeaderToggle}
								/>
							</div>
						</div>
					</div>
				) : (
					''
				)}
				{!useGuidedResponseFlow && (
					<AppliedPathwaysComponent
						contextData={inputData}
						correlationKey={this.props.correlationKey}
						decisionSupportConfig={this.props.decisionSupportConfig}
						isLoading={this.props.isLoading}
						onCompleted={this.handleAppliedPathwaysFlowComplete}
						onChainFlow={this.onChainFlow}
					/>
				)}
				{useGuidedResponseFlow && (
					<GuidedResponseComponent
						contextData={inputData}
						correlationKey={this.props.correlationKey}
						decisionSupportConfig={this.props.decisionSupportConfig}
						chainGuidedResponseFlowFromAp={this.state.chainGuidedResponseFlowFromAp}
						chainedFlowUniqueToken={this.state.chainedFlowUniqueToken}
						chainedFlowDecisionSupportSessionId={this.props.appliedPathways.workbox.id}
						goBackToAppliedPathways={this.goBackToAppliedPathways}
						enableDebuggerFromAp={this.state.enableDebuggerFromAp}
						isLoading={this.props.isLoading}
						onCompleted={this.handleGuidedResponseFlowComplete}
						productInstanceId={this.props.auth.productInstanceId}
						patientReferenceId={this.props.patient.referenceId}
						careOrderVisitIdentifier={this.props.activeCareOrder.activeCareOrderDetails.careOrderVisitIdentifier}
					/>
				)}
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		activeCareOrder: state.careOrder,
		activePatient: state.activePatient,
		appliedPathways: state.appliedPathways,
		auth: state.auth,
		availabilitySearchConfig: state.config.availabilitySearch,
		config: state.config,
		correlationKey: state.session.correlationKey,
		decisionSupportConfig: state.config.decisionSupport,
		decisionSupportOutput: state.decisionSupport,
		flowSessionResponse: state.guidedResponse.flowSessionResponse,
		isLoading: selectIsDecisionSupportLoading(state),
		patient: state.activePatient.details,
		patientConfig: state.config.patient,
	};
}

const selectIsDecisionSupportLoading = (state) => {
	return state.ajaxStatus.isLoading.some(type => [
		types.APPLIEDPATHWAYS_FETCH_WORKBOX,
		types.APPLIEDPATHWAYS_WORKFLOW_NEXT,
		types.APPLIEDPATHWAYS_WORKFLOW_PREVIOUS,
		types.GUIDED_RESPONSE_START_FLOW,
		types.GUIDED_RESPONSE_CONTINUE_FLOW,
		types.GUIDED_RESPONSE_PREVIOUS,
	].includes(type))
}

export default connect(mapStateToProps)(DecisionSupportView);
