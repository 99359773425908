import authCookie from 'lib/authCookie';
import axios, { AxiosInstance, ResponseType } from 'axios';
import { logoutUser } from 'actions/authActions';
import { store } from 'store';
import { ApiState, VoidApiState } from 'types';
import { getErrorMessage } from 'utilities';
import qs from 'qs';

declare module 'axios' {
	export interface AxiosRequestConfig {
		requestId?: string;
	}
}

interface ApiEndpointOptions {
	responseType?: ResponseType;
	requestControllers?: Map<string, AbortController>;
}

export const baseApi = (requestControllers?: Map<string, AbortController>): AxiosInstance => {
	const authState = authCookie.get();
	const instance = axios.create({
		baseURL: '/api',
		headers: authState.token ? { Authorization: `Bearer ${authState.token}` } : {},
	});

	instance.interceptors.request.use(
		(config) => {
			if (requestControllers) {
				const method = config.method?.toUpperCase() || 'GET';
				const url = config.url || '';
				const params = config.params ? qs.stringify(config.params) : '';

				const requestId = `${method}-${url}?${params}`;
				config.requestId = requestId;

				if (requestControllers.has(requestId)) {
					requestControllers.get(requestId)?.abort();
				}
				const controller = new AbortController();
				config.signal = controller.signal;
				requestControllers.set(requestId, controller);
			}
			return config;
		},
		(error) => Promise.reject(error),
	);

	instance.interceptors.response.use(
		(response) => {
			if (requestControllers && response.config.requestId) {
				const requestId = response.config.requestId;
				requestControllers.delete(requestId);
			}
			return response;
		},
		(error) => {
			if (requestControllers && error.config && error.config.requestId) {
				const requestId = error.config.requestId;
				requestControllers.delete(requestId);
			}

			if (!axios.isCancel(error)) {
				if (error.response && error.response.status === 401) {
					authCookie.clear();
					store.dispatch(logoutUser());
				}

				if (typeof error.response === 'undefined') {
					// do something
				}
			}

			return Promise.reject(error);
		},
	);

	return instance;
};

export const cancelAllRequests = () => {
	console.log('This should not be called anymore');
};

export enum ApiMethod {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
	DELETE = 'delete',
	PATCH = 'patch',
}

export const createApiEndpoint = <InputData>(route: string, method: ApiMethod, options?: ApiEndpointOptions) => {
	const { responseType = 'json', requestControllers } = options || {};
	const api = baseApi(requestControllers);

	const callApi = async <T>(setState: (result: ApiState<T>) => void, inputData?: InputData): Promise<boolean> => {
		let result: ApiState<T> = {
			loading: true,
			data: null,
			error: null,
		};
		setState(result);

		try {
			const { data, headers } = await api.request<T>({
				method,
				url: route,
				data: inputData,
				responseType,
			});
			result = {
				loading: false,
				data: data,
				error: null,
				contentType: headers['content-type'],
				contentDisposition: headers['content-disposition'],
			};
			setState(result);
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			result = { loading: false, data: null, error: errorMessage };
			if (!axios.isCancel(error)) {
				setState(result);
			}
		}
		return result.error === null;
	};

	return callApi;
};

export const createVoidApiEndpoint = <InputData>(route: string, method: ApiMethod, options?: ApiEndpointOptions) => {
	const { responseType = 'json', requestControllers } = options || {};
	const api = baseApi(requestControllers);

	const callApi = async <T>(setState: (result: VoidApiState) => void, inputData?: InputData): Promise<boolean> => {
		let result: VoidApiState = {
			processing: true,
			completed: false,
			error: null,
		};
		setState(result);

		try {
			await api.request<T>({
				method,
				url: route,
				data: inputData,
				responseType,
			});
			result = { processing: false, completed: true, error: null };
			setState(result);
		} catch (error) {
			const errorMessage = getErrorMessage(error);
			result = { processing: false, completed: false, error: errorMessage };
			if (!axios.isCancel(error)) {
				setState(result);
			}
		}
		return result.error === null;
	};

	return callApi;
};

export default baseApi;
