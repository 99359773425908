import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from 'reactstrap';

export default class ManageInformation extends React.Component {
    constructor(props) {
        super(props);
        let userDetails = Object.assign({}, props.details);
        this.state = {
            user: userDetails,
            isEditMode: false,
            isOpen: true,
            isFirstNameValid: true,
            isLastNameValid: true,
            isPhoneValid: true
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    onSubmit = (event) => {
        event.preventDefault();
        if (this.validate(event)) {
            this.props.onSubmit(this.state.user);
        }
    }

    validate = () => {
        var validationSet = {
            isFirstNameValid: this.validateField("firstName", this.state.user.firstName),
            isLastNameValid: this.validateField("lastName", this.state.user.lastName),
            isPhoneValid: this.validateField("phone", this.state.user.phone)
        }

        return Object.keys(validationSet).every(function (x) {
            return (validationSet[x] === true);
        });
    }

    validateField = (field, value) => {

        let isValid = false;

        switch (field) {
            case 'firstName':
                isValid = value && value.trim() !== '' ? true : false;
                this.setState({ isFirstNameValid: isValid });
                return isValid;

            case 'lastName':
                isValid = value && value.trim() !== '' ? true : false;
                this.setState({ isLastNameValid: isValid });
                return isValid;

            case 'phone':
                let phoneExp = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/);
                isValid = phoneExp.test(value.trim());
                this.setState({ isPhoneValid: isValid });
                return isValid;

            default:
                return true;
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        let user = {
            ...this.state.user,
            [name]: value
        };
        this.setState({ user });
    };

    handleEdit = (event) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            isEditMode: !this.state.isEditMode
        });
    }

    handleCancel = (event) => {
        event.preventDefault();
        var revertedUser = {
            ...this.state.user,
            firstName: this.props.details.firstName,
            lastName: this.props.details.lastName,
            phone: this.props.details.phone
        };

        this.setState({
            ...this.state,
            isEditMode: !this.state.isEditMode,
            user: revertedUser,
            isFirstNameValid: true,
            isLastNameValid: true,
            isPhoneValid: true
        });
    };

    render() {
        return (
            <div className="col-lg-12 col-md-10 col-sm-12" style={{ padding: "20px" }}>
                <Form onSubmit={this.onSubmit}>
                    <Row>
                        <Col lg="3">
                            <FormGroup>
                                <Label for="firstName" className="fw-bold">FIRST NAME</Label>
                                <p hidden={this.state.isEditMode}>{!this.props.details.firstName ? <span>{'-'}</span> : this.props.details.firstName}</p>
                                <div hidden={!this.state.isEditMode}>
                                    <Input type="text" name="firstName" id="firstName" placeholder="First Name" invalid={(!this.state.isFirstNameValid) ? true : undefined}
                                        onChange={this.handleChange} value={!this.state.user.firstName ? '' : this.state.user.firstName} />
                                    <small id="firstNameRequired" className="form-text text-muted">Required</small>
                                    <div className="invalid-feedback">Please provide a valid First Name.</div>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <FormGroup>
                                <Label for="lastName" className="fw-bold">LAST NAME</Label>
                                <p hidden={this.state.isEditMode}>{!this.props.details.lastName ? <span>{'-'}</span> : this.props.details.lastName}</p>
                                <div hidden={!this.state.isEditMode}>
                                    <Input type="text" name="lastName" id="lastName" placeholder="Last Name" invalid={(!this.state.isLastNameValid) ? true : undefined}
                                        onChange={this.handleChange} value={!this.state.user.lastName ? '' : this.state.user.lastName} />
                                    <small id="lastNameRequired" className="form-text text-muted">Required</small>
                                    <div className="invalid-feedback">Please provide a valid Last Name.</div>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3">
                            <FormGroup>
                                <Label for="phone" className="fw-bold">PHONE</Label>
                                <p hidden={this.state.isEditMode}>{!this.props.details.phone ? <span>{'-'}</span> : this.props.details.phone}</p>
                                <div hidden={!this.state.isEditMode}>
                                    <NumericFormat className={'form-control' + ((!this.state.isPhoneValid) ? ' is-invalid' : '')} format="(###) ###-####" mask="_" name="phone" id="phone" placeholder="Phone"
                                        onChange={this.handleChange} value={!this.state.user.phone ? '' : this.state.user.phone} />
                                    <small id="phoneRequired" className="form-text text-muted">Required</small>
                                    <div className="invalid-feedback">Please provide a valid Phone.</div>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col lg="3">
                            <FormGroup>
                                <Label className="fw-bold">EMAIL ADDRESS</Label>
                                <p>{!this.props.details.email ? <span>{'-'}</span> : this.props.details.email}</p>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3">
                            <FormGroup>
                                <Label className="fw-bold form-label">USER ROLE</Label>
                                <p>{!this.props.details.role ? <span>{'-'}</span> : this.props.details.role}</p>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3">
                            {this.state.isEditMode === true ?
                                <span>
                                    <Button type="submit" color="primary" style={{ marginRight: '10px' }}>Save</Button>
                                    <Button onClick={this.handleCancel}>Cancel</Button>
                                </span>
                                : <span><Button color="primary" onClick={this.handleEdit} style={{ marginBotton: '20px' }}>Edit</Button></span>}
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}