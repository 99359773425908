import { push } from '@lagunovsky/redux-react-router';
import * as actionTypes from './actionTypes';

export function getPatientReferenceId(refType, refValue) {
	return {
		type: actionTypes.TOKEN_LINK_PATIENT_REFID_LOOKUP,
		payload: {
			request: {
				method: 'get',
				url: `start/patientLookup?refType=${refType}&refValue=${refValue}`
			}
		}
	};
}

export function searchPatient(patientSearchCriteria) {
	return {
		type: actionTypes.SEARCH_PATIENT,
		payload: {
			request: {
				method: 'get',
				url: `patient/search?firstName=${patientSearchCriteria.firstName}&lastName=${patientSearchCriteria.lastName}&dateOfBirth=${patientSearchCriteria.dateOfBirth}&memberId=${patientSearchCriteria.memberId}`
			}
		}
	};
}

export function getPatientById(referenceId) {
	return {
		type: actionTypes.PATIENT_DETAILS,
		payload: {
			request: {
				method: 'get',
				url: `patient/${referenceId}/details`
			}
		}
	};
}

export function createPatient(savePatientCriteria) {
	return {
		type: actionTypes.PATIENT_SAVE,
		payload: {
			request: {
				method: 'post',
				url: 'patient/create',
				data: {
					firstName: savePatientCriteria.firstName,
					middleName: savePatientCriteria.middleName,
					lastName: savePatientCriteria.lastName,
					addressLine1: savePatientCriteria.addressLine1,
					addressLine2: savePatientCriteria.addressLine2,
					cityName: savePatientCriteria.cityName,
					stateId: savePatientCriteria.stateId,
					zipCode: savePatientCriteria.zipCode,
					gender: savePatientCriteria.gender,
					dateOfBirth: savePatientCriteria.dateOfBirth,
					homePhone: savePatientCriteria.homePhone,
					mobilePhone: savePatientCriteria.mobilePhone,
					alternatePhone: savePatientCriteria.alternatePhone,
					email: savePatientCriteria.email,
					guardianName: savePatientCriteria.guardianName,
					memberId: savePatientCriteria.memberId,
					groupNumber: savePatientCriteria.groupNumber
				}
			}
		}
	};
}

export function updatePatient(savePatientCriteria) {
	return {
		type: actionTypes.PATIENT_UPDATE,
		payload: {
			request: {
				method: 'put',
				url: `patient/${savePatientCriteria.patientId}/update`,
				data: {
					patientId: savePatientCriteria.patientId,
					referenceId: savePatientCriteria.referenceId,
					firstName: savePatientCriteria.firstName,
					middleName: savePatientCriteria.middleName,
					lastName: savePatientCriteria.lastName,
					addressLine1: savePatientCriteria.addressLine1,
					addressLine2: savePatientCriteria.addressLine2,
					cityName: savePatientCriteria.cityName,
					stateId: savePatientCriteria.stateId,
					zipCode: savePatientCriteria.zipCode,
					gender: savePatientCriteria.gender,
					dateOfBirth: savePatientCriteria.dateOfBirth,
					homePhone: savePatientCriteria.homePhone,
					mobilePhone: savePatientCriteria.mobilePhone,
					alternatePhone: savePatientCriteria.alternatePhone,
					email: savePatientCriteria.email,
					guardianName: savePatientCriteria.guardianName,
					memberId: savePatientCriteria.memberId,
					groupNumber: savePatientCriteria.groupNumber
				}
			}
		}
	};
}

export function getPatientHistoryById(patientId) {
	return {
		type: actionTypes.PATIENT_HISTORY,
		payload: {
			request: {
				method: 'get',
				url: `patient/${patientId}/history`
			}
		}
	};
}

export function loadPatientSavePageData() {
	return {
		type: actionTypes.LOAD_PATIENT_SAVE_PAGE_DATA,
		payload: {
			request: {
				method: 'get',
				url: `pagedata/patientsave`
			}
		}
	};
}

export function startBookingProcess(siteConfig) {
	let url = '/availability/search';
	if (siteConfig.decisionSupport.useDecisionSupport) {
		url = '/decisionSupport';
	}
	return function (dispatch) {
		dispatch(push(url));
	};
}

export function sendCareOrderToPatient(notificationDetails) {
	return {
		type: actionTypes.PATIENT_SEND_CARE_ORDER_NOTIFICATION,
		payload: {
			request: {
				method: 'post',
				url: `patient/sendCareOrderNotification`,
				data: {
					organizationName: notificationDetails.organizationName,
					phoneNumber: notificationDetails.phoneNumber,
					email: notificationDetails.email,
					careOrderLinkExpirationDate: notificationDetails.careOrderLinkExpirationDate,
					language: notificationDetails.language,
					careOrderVisitReferenceId: notificationDetails.careOrderVisitReferenceId,
					idProductInstance: notificationDetails.idProductInstance,
					userName: notificationDetails.userName,
					patientId: notificationDetails.patientId,
					messageType: notificationDetails.messageType
				}
			}
		}
	};
}