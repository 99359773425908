/**
 * @format
 */

import { push } from '@lagunovsky/redux-react-router';
import moment from 'moment';
import AppointmentCancelView from './views/appointment/appointmentCancelView';
import AppointmentDetailsView from './views/appointment/appointmentDetailsView';
import AppointmentEditView from './views/appointment/appointmentEditView';
import AppointmentRescheduleView from './views/appointment/appointmentRescheduleView';
import AvailabilitySearchView from './features/searchAsync/availabilitySearchView';
import NotFound from './components/NotFound';
import InstanceUnavailable from './components/common/instanceUnavailable';
import PermissionWarning from './components/common/permissionWarning';
import LandingPageSelector from './components/landingPageSelector';
import mhd2Permissions from './constants/permissions';
import IdlePopupContainer from './utilities/idleTimer/idleTimerContainter';
import BookAppointmentView from './views/bookAppointment/bookAppointmentView';
import DecisionSupportView from './views/decisionSupport/decisionSupportView';
import CreateOrderView from './views/orderManagement/createOrderView';
import SearchOrdersView from './views/orderManagement/searchOrdersView';
import PatientAddView from './views/patient/patientAddView';
import PatientDetailsView from './features/patient/patientDetailsView';
import PatientEditView from './views/patient/patientEditView';
import PatientSearchView from './views/patient/patientSearchView';
import SingleSignOnRedirector from './views/redirectors/singleSignOnRedirector';
import StartCreateCareOrderRedirector from './views/redirectors/startCreateCareOrderRedirector';
import PatientRedirector from './views/redirectors/patientRedirector';
import AppointmentRedirector from './views/redirectors/appointmentRedirector';
import RequestAppointmentDetailsView from './views/requestAppointment/requestAppointmentDetailsView';
import RequestAppointmentView from './views/requestAppointment/requestAppointmentView';

import Login from './features/user/loginView';
import Logout from './features/user/logoutView';
import SsoAccessDenied from './features/user/ssoAccessDeniedView';
import FirstLoginView from './features/user/firstLoginView';
import ForgotPasswordView from './features/user/forgotPasswordView';
import ResetPasswordView from './features/user/resetPasswordView';
import UserProfileView from './features/user/userProfileView';

import { RouteRenderer } from 'components/routes';

moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ss';

/* * * WARNING - All changes to routes must update the Rd2RouterSwitch, URL Builder and/or Action method * * */

/* Rd2RouteSwitch - builds the react router switch component for RD2 routes */
export function Rd2RouteSwitch(props) {
	const { instance, location, isAuthenticated } = props;

	if (instance && instance.isInstanceUnavailable && location.pathname !== '/logout') {
		return <InstanceUnavailable message={instance.instanceUnavailableText} />;
	}

	const routes = [
		{ path: '/login', element: <Login />, publicAccess: true },
		{ path: '/logout', element: <Logout />, publicAccess: true },
		{ path: '/forgotpassword', element: <ForgotPasswordView />, publicAccess: true },
		{ path: '/resetpassword', element: <ResetPasswordView />, publicAccess: true },
		{ path: '/accessdenied', element: <PermissionWarning />, publicAccess: true },
		{ path: '/external/login/:token', element: <SingleSignOnRedirector />, publicAccess: true },
		{ path: '/external/accessdenied', element: <SsoAccessDenied />, publicAccess: true },
		{ path: '/', element: <LandingPageSelector />, permission: false },
		{
			path: '/appointment/:referenceId/cancel',
			element: <AppointmentCancelView />,
			permission: mhd2Permissions.appointmentManagement,
		},
		{
			path: '/appointment/:referenceId/details',
			element: <AppointmentDetailsView />,
			permission: mhd2Permissions.appointmentManagement,
		},
		{
			path: '/cancelledAppointment/:referenceId/details',
			element: <AppointmentDetailsView isCancelled={true} />,
			permission: mhd2Permissions.appointmentManagement,
		},
		{
			path: '/appointment/:referenceId/edit',
			element: <AppointmentEditView />,
			permission: mhd2Permissions.appointmentManagement,
		},
		{
			path: '/appointment/:referenceId/reschedule',
			element: <AppointmentRescheduleView />,
			permission: mhd2Permissions.appointmentManagement,
		},
		{
			path: '/appointment/request/:requestId/details',
			element: <RequestAppointmentDetailsView />,
			permission: mhd2Permissions.appointmentManagement,
		},
		{
			path: '/availability/search',
			element: <AvailabilitySearchView />,
			permission: mhd2Permissions.appointmentManagement,
		},
		{
			path: '/decisionSupport',
			element: <DecisionSupportView />,
			permission: mhd2Permissions.appointmentManagement,
		},
		{
			path: '/orders/create',
			element: <CreateOrderView />,
			permission: mhd2Permissions.orderManagement,
		},
		{
			path: '/orders/search',
			element: <SearchOrdersView />,
			permission: mhd2Permissions.orderManagement,
		},
		{
			path: '/patient/:referenceId/book/:availabilityId',
			element: <BookAppointmentView />,
			permission: mhd2Permissions.appointmentManagement,
		},
		{
			path: '/patient/search',
			element: <PatientSearchView />,
			permission: mhd2Permissions.patientManagement,
		},
		{
			path: '/patient/create',
			element: <PatientAddView />,
			permission: mhd2Permissions.patientManagement,
		},
		{
			path: '/patient/:referenceId/details',
			element: <PatientDetailsView />,
			permission: mhd2Permissions.patientManagement,
		},
		{
			path: '/patient/:referenceId/edit',
			element: <PatientEditView />,
			permission: mhd2Permissions.patientManagement,
		},
		{ path: '/provider/:serviceSiteId/appointment/request', element: <RequestAppointmentView /> },
		{ path: '/user/profile', element: <UserProfileView />, permission: false },
		{ path: '/user/firstlogin', element: <FirstLoginView />, permission: false },
		{ path: '/start/order/create', element: <StartCreateCareOrderRedirector />, permission: false },
		{ path: '/start/patient/:refValue', element: <PatientRedirector />, permission: false },
		{ path: '/start/appointment/:refValue', element: <AppointmentRedirector />, permission: false },
		{ path: '*', element: <NotFound />, permission: false },
	];

	return (
		<>
			{isAuthenticated && <IdlePopupContainer />}
			<RouteRenderer routes={routes} />
		</>
	);
}

export function menuItems() {
	let menuItems = [
		{ name: 'Search Patient', requiredPermission: mhd2Permissions.patientManagement, action: patientSearchUrl() },
		{
			name: 'Create Order',
			requiredPermission: mhd2Permissions.orderManagement,
			action: createCareOrderUrl(),
			requiresActivePatient: 'true',
		},
		{ name: 'Search Orders', requiredPermission: mhd2Permissions.orderManagement, action: searchOrdersUrl() },
	];
	return menuItems;
}

/* Route Methods */
export function loginUrl() {
	return '/login'
}

export function login() {
	return push(loginUrl());
}

export function createCareOrderUrl() {
	return '/orders/create';
}

export function createCareOrder() {
	return push(createCareOrderUrl());
}

export function forgotPasswordUrl() {
	return '/forgotpassword';
}

export function forgotPassword() {
	return push(forgotPasswordUrl());
}

export function patientAddUrl(firstName = '', lastName = '', dateOfBirth, memberId = '') {
	dateOfBirth = dateOfBirth ? moment(dateOfBirth).format('YYYY-MM-DD') : '';
	return `/patient/create?firstName=${firstName}&lastName=${lastName}&dateOfBirth=${dateOfBirth}&memberId=${memberId}`;
}

export function patientAdd(firstName = '', lastName = '', dateOfBirth, memberId = '') {
	return push(patientAddUrl(firstName, lastName, dateOfBirth, memberId));
}

export function patientSearchUrl() {
	return '/patient/search';
}

export function patientSearch() {
	return push(patientSearchUrl());
}

export function patientDetailsUrl(referenceId) {
	return `/patient/${referenceId}/details`;
}

export function patientDetails(referenceId) {
	return push(patientDetailsUrl(referenceId));
}

export function patientEditUrl(referenceId) {
	return `/patient/${referenceId}/edit`;
}

export function patientEdit(referenceId) {
	return push(patientEditUrl(referenceId));
}

export function availabilitySearchUrl() {
	return '/availability/search';
}

export function availabilitySearch() {
	return push(availabilitySearchUrl());
}

export function appointmentDetailsUrl(referenceId) {
	return `/appointment/${referenceId}/details`;
}

export function cancelledAppointmentDetailsUrl(referenceId) {
	return `/cancelledAppointment/${referenceId}/details`;
}

export function appointmentEditUrl(referenceId) {
	return `/appointment/${referenceId}/edit`;
}

export function appointmentEdit(referenceId) {
	return push(appointmentEditUrl(referenceId));
}

export function rescheduleAppointmentUrl(referenceId) {
	return `/appointment/${referenceId}/reschedule`;
}

export function cancelAppointmentUrl(referenceId) {
	return `/appointment/${referenceId}/cancel`;
}

export function requestAppointmentUrl(serviceSiteId) {
	return `/provider/${serviceSiteId}/appointment/request`;
}

export function requestAppointmentDetailsUrl(requestId) {
	return `/appointment/request/${requestId}/details`;
}

export function appointmentDetails(referenceId) {
	return push(appointmentDetailsUrl(referenceId));
}

export function cancelledAppointmentDetails(referenceId) {
	return push(cancelledAppointmentDetailsUrl(referenceId));
}

export function rescheduleAppointment(referenceId) {
	return push(rescheduleAppointmentUrl(referenceId));
}

export function cancelAppointment(referenceId) {
	return push(cancelAppointmentUrl(referenceId));
}

export function startBookingProcessUrl(useDecisionSupport) {
	return useDecisionSupport ? decisionSupportUrl() : availabilitySearchUrl();
}

export function startBookingProcess(useDecisionSupport) {
	return push(startBookingProcessUrl(useDecisionSupport));
}

export function decisionSupportUrl() {
	return '/decisionSupport';
}

export function decisionSupport() {
	return push(decisionSupportUrl());
}

export function logoutUrl() {
	return '/logout';
}

export function logoutUser() {
	return push(logoutUrl());
}

export function createOrderUrl() {
	return '/orders/create';
}

export function createOrder() {
	return push(createOrderUrl());
}

export function searchOrdersUrl() {
	return '/orders/search';
}

export function bookAppointmentUrl(patientReferenceId, timeSlotId) {
	return `/patient/${patientReferenceId}/book/${timeSlotId}`;
}

export function searchOrders() {
	return push(searchOrdersUrl());
}

export function requestAppointment(serviceSiteId) {
	return push(requestAppointmentUrl(serviceSiteId));
}

export function requestAppointmentDetails(requestId) {
	return push(requestAppointmentDetailsUrl(requestId));
}

export function routeToBooking(patientReferenceId, timeSlotId) {
	return push(bookAppointmentUrl(patientReferenceId, timeSlotId));
}
