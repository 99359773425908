import { ColDef, ColDefField } from 'ag-grid-community';
import { IGridColumn } from './types';

export function mapToAgGridColumns<TData>(columns: IGridColumn[]): ColDef<TData>[] {
	return columns.map((col) => {
		const agColDef: ColDef<TData> = {
			...(col.field && { field: col.field as ColDefField<TData, any> }),
			headerName: col.headerName,
			sortable: col.sortable,
			filter: col.filterable,
			resizable: col.resizable,
			width: col.width,
			cellRenderer: col.cellRenderer,
			headerComponent: col.headerComponent,
			headerComponentParams: col.headerComponentParams,
			hide: col.hide,
			suppressHeaderMenuButton: col.suppressHeaderMenuButton ? col.suppressHeaderMenuButton : true,
		};

		return agColDef;
	});
}
