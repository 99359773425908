/**
 * @format
 */

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import LoadingIndicator from '../../components/loadingIndicator';
import * as routes from '../../routes';
import { getAppointmentByReferenceId, getCancelledAppointmentByReferenceId } from '../../actions/appointmentActions';
import {
	startPatientSearchProcess,
	clearBookingContext,
	clearAgentInstructions,
	startBookingProcess,
	setCancelRescheduleActionReferrer,
} from '../../actions/rd2RefactorActions';
import AppointmentDetailsOverview from '../../components/appointment/appointmentDetailsOverview';
import * as guidedResponseActions from '../../actions/guidedResponseActions';
import { bindActionCreators } from 'redux';
import { APPOINTMENT_DETAILS } from 'constants/actionReferrer';
import { withRouter } from 'components/hoc';

export class AppointmentDetailsView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: '',
			isDetailsLoading: false,
			isRescheduled: false,
			pastModal: false,
			rescheduledAppointmentDetails: {},
			previousAppointmentModal: false,
		};
	}

	appointmentDetails = (referenceId) => {
		this.setState({ isDetailsLoading: true }, () => {
			if (this.props.isCancelled) {
				this.props
					.dispatch(getCancelledAppointmentByReferenceId(referenceId))
					.then((response) => {
						if (response.error) {
							this.setState({ error: 'An error occurred while trying to get the appointment details.' });
						}

						const decisionSupportSessionId = response.payload?.data?.decisionSupportSessionId;
						this.getSessionQuestionsAndAnswers(decisionSupportSessionId);
					})
					.catch((err) => {
						this.setState({ error: 'Error' });
					});
			}
			else {
				this.props
					.dispatch(getAppointmentByReferenceId(referenceId))
					.then((response) => {
						if (response.error) {
							this.setState({ error: 'An error occurred while trying to get the appointment details.' });
						}

						const decisionSupportSessionId = response.payload?.data?.decisionSupportSessionId;
						this.getSessionQuestionsAndAnswers(decisionSupportSessionId);
					})
					.catch((err) => {
						this.setState({ error: 'Error' });
					});
			}
		});
	};

	newSearch() {
		this.props.dispatch(startPatientSearchProcess());
	}

	bookAnotherAppointment() {
		this.props.dispatch(clearBookingContext());
		this.props.dispatch(clearAgentInstructions());
		this.props.dispatch(
			startBookingProcess(
				this.props.activeCareOrder,
				this.props.decisionSupportOutput,
				this.props.availabilitySearchConfig,
				this.props.activePatient.details,
				this.props.useDecisionSupport,
			),
		);
	}

	togglePastModal = () => {
		const pastModal = this.state.pastModal;
		this.setState({
			pastModal: !pastModal,
		});
	};

	verifyFuture = () => {
		return moment.utc(this.props.details.appointmentDateTimeUtc).isAfter();
	};

	handleCancelClick = () => {
		if (this.verifyFuture()) {
			this.props.dispatch(routes.cancelAppointment(this.props.details.referenceId));
		} else {
			this.togglePastModal();
		}
	};

	handleEditClick = (e) => {
		e.preventDefault();
		this.props.dispatch(routes.appointmentEdit(this.props.details.referenceId));
	};

	handlePreviousDetailsClick = (e) => {
		e.preventDefault();
		this.setState({
			previousAppointmentModal: true,
		});
	};

	togglePreviousAppointmentDetailsModal = () => {
		const previousAppointmentModal = this.state.previousAppointmentModal;
		this.setState({
			previousAppointmentModal: !previousAppointmentModal,
		});
	};

	handleNewSearchClick = (e) => {
		e.preventDefault();
		this.newSearch();
	};

	handleBookAnotherAppointmentClick = (e) => {
		e.preventDefault();
		this.bookAnotherAppointment();
	};

	handleRescheduleClick = () => {
		this.props.dispatch(setCancelRescheduleActionReferrer(APPOINTMENT_DETAILS));
		this.props.dispatch(routes.rescheduleAppointment(this.props.details.referenceId));
	};

	componentDidMount() {
		const params = this.props.params;
		if (this.props.cancelRescheduleInfo.previousAppointmentReferenceId !== '') {
			let rescheduledAppointmentDetails = this.props.cancelRescheduleInfo.previousAppointmentDetails;
			let rescheduledMethod = this.props.cancelRescheduleInfo.rescheduleMethod;
			this.setState({ rescheduledAppointmentDetails, isRescheduled: true, rescheduledMethod });
		}
		this.appointmentDetails(params.referenceId);
	}

	getSessionQuestionsAndAnswers = (decisionSupportSessionId) => {
		if (decisionSupportSessionId) {
			this.props.actions.guidedResponse.getSessionQuestionsAndAnswers(decisionSupportSessionId).then(() => {
				this.setState({ isDetailsLoading: false });
			});
		}
		else {
			this.setState({ isDetailsLoading: false });
		}
	}

	render() {
		let rescheduleMethod = this.props.cancelRescheduleInfo.rescheduleMethod;

		return (
			<>
				{this.state.isDetailsLoading && <LoadingIndicator />}
				{!this.state.isDetailsLoading && (
					<AppointmentDetailsOverview
						activeCareOrder={this.props.activeCareOrder}
						agentInstructions={this.props.agentInstructions.agentPostBookingInstructions}
						appointmentManagementConfig={this.props.appointmentManagementConfig}
						bookAnotherAppointment={this.handleBookAnotherAppointmentClick}
						// decisionSupportSessionId={this.props.details.decisionSupportSessionId}
						details={this.props.details}
						enableDecisionSupportHistory={this.props.enableDecisionSupportHistory}
						handleCancelClick={this.handleCancelClick}
						handleEditClick={this.handleEditClick}
						handleNewSearchClick={this.handleNewSearchClick}
						handlePreviousDetailsClick={this.handlePreviousDetailsClick}
						handleRescheduleClick={this.handleRescheduleClick}
						navigate={this.props.navigate}
						isGroupNumberActive={this.props.isGroupNumberActive}
						isGroupNumberRequired={this.props.isGroupNumberRequired}
						isMemberIdActive={this.props.isMemberIdActive}
						isMemberIdRequired={this.props.isMemberIdRequired}
						isRescheduled={this.state.isRescheduled}
						memberIdFieldLabel={this.props.memberIdFieldLabel}
						pastModal={this.state.pastModal}
						previousAppointmentModal={this.state.previousAppointmentModal}
						providerFieldConfig={this.props.providerFieldConfig}
						rescheduledAppointmentDetails={this.state.rescheduledAppointmentDetails}
						rescheduleMethod={rescheduleMethod}
						schedulingConfig={this.props.schedulingConfig}
						selectedAppointmentReferenceId={this.props.details.referenceId}
						sessionQuestionsAndAnswersInfo={this.props.sessionQuestionsAndAnswersInfo}
						showInsuranceOnAppointmentDetails={this.props.showInsuranceOnAppointmentDetails}
						showProviderResponseApptDetails={this.props.showProviderResponseApptDetails}
						showProviderResponsePatientHistory={this.props.showProviderResponsePatientHistory}
						showReasonForVisitApptDetails={this.props.showReasonForVisitApptDetails}
						togglePastModal={this.togglePastModal}
						togglePreviousAppointmentDetailsModal={this.togglePreviousAppointmentDetailsModal}
						verifyFuture={this.verifyFuture}
						isAppointmentCancelled={this.props.isCancelled}
					/>
				)}
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		activeCareOrder: state.careOrder,
		activePatient: state.activePatient,
		agentInstructions: state.appointment.agentInstructions,
		appointmentManagementConfig: state.config.appointmentManagement,
		availabilitySearchConfig: state.config.availabilitySearch,
		cancelRescheduleInfo: state.appointment.cancelRescheduleInfo,
		decisionSupportOutput: state.decisionSupport,
		details: state.appointment.details,
		enableRequestAppointment: state.config.system.enableRequestAppointment,
		enableDecisionSupportHistory: state.config.decisionSupport.enableDecisionSupportHistory,
		isGroupNumberActive: state.config.system.isGroupNumberActive,
		isGroupNumberRequired: state.config.system.isGroupNumberRequired,
		isMemberIdActive: state.config.system.isMemberIdActive,
		isMemberIdRequired: state.config.system.isMemberIdRequired,
		memberIdFieldLabel: state.config.system.memberIdFieldLabel,
		providerFieldConfig: state.config.provider,
		referralSiteId: state.auth.referralSiteId,
		referralSystemId: state.auth.referralSystemId,
		schedulingConfig: state.config.scheduling,
		sessionQuestionsAndAnswersInfo: state.decisionSupport.sessionQuestionsAndAnswersInfo,
		showInsuranceOnAppointmentDetails: state.config.scheduling.showInsuranceOnAppointmentDetails,
		showInsuranceOnBookAppointment: state.config.scheduling.showInsuranceOnBookAppointment,
		showProviderResponseApptDetails: state.config.scheduling.showProviderResponseApptDetails,
		showProviderResponsePatientHistory: state.config.scheduling.showProviderResponsePatientHistory,
		showReasonForVisitApptDetails: state.config.scheduling.showReasonForVisitApptDetails,
		token: state.auth.token,
		useDecisionSupport: state.config.decisionSupport.useDecisionSupport,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			guidedResponse: bindActionCreators(guidedResponseActions, dispatch),
		},
		dispatch
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppointmentDetailsView));
