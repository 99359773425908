import LoggerUtils from 'lib/loggerUtils';
import * as actionTypes from '../actions/actionTypes';

const loggerMiddleware = store => next => action => {
    if (actionsToLog.includes(action.type)) {

        LoggerUtils.logTrace(`redux-store before ${action.type}:`, store.getState());
        const result = next(action);
        LoggerUtils.logTrace(`redux-store after ${action.type}:`, store.getState());

        return result;
    }

    return next(action);
};

const actionsToLog = [
    actionTypes.SET_INITIAL_CUSTOM_FIELD_ANSWERS,
    actionTypes.CLEAR_BOOKING_CONTEXT,
    // LOCATION_CHANGE,
]

export default loggerMiddleware;