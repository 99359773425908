import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { validateZipCode } from '../../lib/validation';
import { TextInput } from './index';

export default function ZipCodeInput(props) {
	const { onChange, ...rest } = props;
	return <NumericFormat {...rest} customInput={TextInput} validate={validateZipCode} onChange={onChange} format="#####" mask="_" />;
}

ZipCodeInput.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	value: PropTypes.string
}