import { MouseEventHandler, useState } from "react";
import { Alert, Button, Col, Form, FormGroup, Row } from "reactstrap"
import UserEmailInput from "./userEmailInput";
import LoginButton from "./loginButton";

const ForgotPasswordForm = (props: {
    onSubmit: Function,
    onCancel: Function,
    showSuccess: boolean,
    showError: boolean,
    isSaving: boolean
}) => {

    const [email, setEmail] = useState('');

    const isValid = () => {
        return email && email.trim() !== '';
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (isValid()) {
            props.onSubmit(email);
        }
    }

    const handleCancel = (e: any) => {
        e.preventDefault()
        props.onCancel();
    }

    return (
        <Form onSubmit={handleSubmit} className="forgotPasswordForm">
            {!props.showSuccess && !props.showError &&
                <>
                    <UserEmailInput value={email} handleChange={(e) => setEmail(e.target.value)} />
                    <LoginButton
                    isLoading={props.isSaving}
                    disabled={!isValid()}
                    text="Generate Password Reset Email"
                    loadingText="Generating Email..."
                    isValid={() => isValid()}
                    />
                    <CancelForgotPasswordButton cancel={handleCancel} />
                </>
            }
            {(props.showSuccess || props.showError) &&
                <ReturnToLogin showSuccess={props.showSuccess} cancel={handleCancel} />
            }
        </Form>
    )
}

export default ForgotPasswordForm;

const CancelForgotPasswordButton = (props: { cancel: MouseEventHandler<HTMLButtonElement> }) => {
    return (
        <FormGroup className="loginButton">
            <Button
                color="secondary"
                block
                onClick={props.cancel}
            >
                Cancel
            </Button>
        </FormGroup>
    )
}

const ReturnToLogin = (props: {
    showSuccess: boolean,
    cancel: MouseEventHandler<HTMLButtonElement>
}) => {

    const alertStyle: React.CSSProperties = {
        width: '100%',
        textAlign: 'center',
    }

    const successMessage = "Request received! If this is a recognized email address, you should receive a password reset email within 5-10 minutes. If you don't receive your email after this time, please contact MHD Support.";
    const errorMessage = "Sorry! Please contact MHD Support to reset your password.";

    return (
        <Row style={{ marginBottom: '6px' }}>
            <Col>
                <Alert
                    color={props.showSuccess ? "success" : "danger"}
                    style={alertStyle}
                >
                    {props.showSuccess ? successMessage : errorMessage}
                </Alert>
                <Button
                    color="primary"
                    className="experian-btn"
                    block
                    onClick={props.cancel}
                >
                    Return to Login
                </Button>
            </Col>
        </Row>
    )
}