import './polyfills';
import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
//import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import buildFontAwesomeLibrary from './buildFontAwesomeLibrary';
import { store } from './store';
import { history } from './lib/history';
import { unregister } from './registerServiceWorker';
import * as Sentry from "@sentry/react";
import config from './config';

buildFontAwesomeLibrary();

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: config.sentry.dsn,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
			// new Sentry.BrowserProfilingIntegration(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		profilesSampleRate: 1.0,
		ignoreErrors: ['gtag/js', 'ruxitagentjs'],
	});
}

const rootElement = document.getElementById('root');

if (rootElement) {
    const root = createRoot(rootElement);
    root.render(
        <Provider store={store}>
            <ReduxRouter history={history}>
                <App />
            </ReduxRouter>
        </Provider>
    );
}
unregister();
