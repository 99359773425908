import * as React from 'react';
import { Label, Button } from 'reactstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { PhoneNumber } from '../common/phoneNumber';
import { DataGrid } from '../dataGrid/dataGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defaultBookingDisabledMessage } from '../../lib/misc';
import './patientSearchResultList.css'

export default function PatientSearchResultList(props) {

	const {
		disableRow,
		enablePatientAdd,
		memberIdFieldLabel,
		onBookClick,
		onCreateClick,
		onRowClick,
		patients } = props;

	const genderFieldLabel = props.genderFieldLabel || 'Gender';

	const [isBooking, setIsBooking] = React.useState(false);
	const getTooltipMessage = (currentRow) => {
		return currentRow.isBookingDisabled && currentRow.bookingDisabledMessage
			? currentRow.bookingDisabledMessage
			: currentRow.isBookingDisabled && !currentRow.bookingDisabledMessage
				? defaultBookingDisabledMessage
				: '';
	}

	const handleBookClick = (event, patient, handler) => {
		event.preventDefault();
		setIsBooking(true);
		return handler(patient);
	};

	const handleRowClick = (event, patient) => {
		event.preventDefault();
		setIsBooking(false);
		return onRowClick(patient);
	};

	const getRowClass = (r) => {
		return 'dataGridRow';
	};

	const columnDefs = [
		{
			headerName: "",
			colId: "button",
			field: "button",
			width: 150,
			cellRenderer: (r) => {
				if (r.data && !r.data.isInactive) {
					return <div className='button-center'>
						<Button
							className="bookButton"
							color="primary"
							data-toggle={!r.data.isBookingDisabled ? null : 'tooltip'}
							data-placement="top"
							title={getTooltipMessage(r.data)}
							onClick={(e) => { e.stopPropagation(); handleBookClick(e, r.data, onBookClick)}}
							disabled={disableRow || r.data.isBookingDisabled}
						>
							{isBooking ? (
								<span>
									<FontAwesomeIcon icon="spinner" spin /> Booking...
								</span>
							) : (
								<span>Book</span>
							)}
						</Button>
					</div>
				}
				else {
					return <span></span>
				}
			}
		},
		{
			headerName: 'First Name',
			field: 'firstName'
		},
		{
			headerName: 'Last Name',
			field: 'lastName'
		},
		{
			field: 'memberId',
			headerName: `${memberIdFieldLabel}`,
			cellRenderer: (r) => <span>{r.data.memberId ? r.data.memberId : '-'}</span>
		},
		{
			field: 'gender',
			width: 100,
			headerName: `${genderFieldLabel}`,
			cellRenderer: (r) => <span>{r.data.gender ? r.data.gender.toUpperCase() : '-'}</span>
		},
		{
			field: 'dateOfBirth',
			headerName: 'Date of Birth',
			cellRenderer: (r) => <span>{r.data.dateOfBirth ? moment(r.data.dateOfBirth).format('MM/DD/YYYY') : '-'}</span>
		},
		{
			field: 'addressLine1',
			headerName: 'Address',
			cellRenderer: (r) => <span>{r.data.addressLine1 ? r.data.addressLine1 + ' ' + (r.data.addressLine2 ? r.data.addressLine2 : '') : '-'}</span>
		},
		{
			field: 'homePhone',
			headerName: 'Phone (H)',
			cellRenderer: (r) => <span>{r.data.homePhone ? <PhoneNumber value={r.data.homePhone} /> : '-'}</span>
		},
		{
			field: 'mobilePhone',
			headerName: 'Phone (M)',
			cellRenderer: (r) => <span>{r.data.mobilePhone ? <PhoneNumber value={r.data.mobilePhone} /> : '-'}</span>
		},
		{
			field: 'email',
			headerName: 'Email',
			cellRenderer: (r) => <span>{r.data.email ? r.data.email : '-'}</span>
		},
	]

	return (
			<div>
				{patients.summaryInfo && patients.summaryInfo.totalResultCount <= 0 &&
					<h4 className="patient-result-header text-nowrap">No patients found!</h4>
				}
				{patients.summaryInfo && patients.summaryInfo.totalResultCount > 0 &&
					<div>
					<h4 className="patient-result-header text-nowrap">Patient Results</h4><h4 className="patient-result-subheader">{patients.summaryInfo.totalResultCount} patient{patients.summaryInfo.totalResultCount > 1 ? "s" : ""} found</h4>
					<DataGrid
						autoHeight={true}
						columnDefs={columnDefs}
						data={patients.items}
						suppressRowClickSelection={false}
						onRowClick={handleRowClick}
						getRowClass={getRowClass}
						noRowsMessage="No history records found."
					/>
					</div>
				}
				{patients.summaryInfo && enablePatientAdd &&
					<Label>Can't find the patient you are looking for?  Please <a href="#Create" onClick={onCreateClick}>create</a> a new patient.</Label>
				}
			</div>
		)
}

PatientSearchResultList.propTypes = {
	disableRow: PropTypes.bool.isRequired,
	enablePatientAdd: PropTypes.bool,
	memberIdFieldLabel: PropTypes.string,
	onBookClick: PropTypes.func.isRequired,
	onCreateClick: PropTypes.func.isRequired,
	onRowClick: PropTypes.func.isRequired,
	patients: PropTypes.object,
}
