//Third Party Dependencies
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
	Card,
	CardHeader,
	Col,
	Collapse,
	Row
} from 'reactstrap';

//MHD General Purpose libraries
import LoadingIndicator from '../../components/loadingIndicator';

//Specific Business and UI components
import * as userActions from '../../actions/userActions';
import ManageUserInfo from './comps/manageInformation';
import ManageSecretQuestions from './comps/manageSecretQuestions';
import ChangePassword from './comps/changePassword';
import './css/userProfileView.css'

export class UserProfileView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
			isChangePasswordOpen: false,
			isSecretQuestionsOpen: false,
			isUserInfoOpen: true,
			isUserInfoLoading: false,
			isSecretQuestionsLoading: false,
			isChangePasswordLoading: false,
			isPageLoading: false,
		};

		this.updatePassword = this.updatePassword.bind(this);
	}

	saveUserProfile = (userInfo) => {
		this.updateUserProfile(userInfo, "isUserInfoLoading")
	}

	saveSecretQuestions = (userInfo) => {
		this.updateUserProfile(userInfo, "isSecretQuestionsLoading")
	}

	updateUserProfile = (userInfo, isLoading) => {
		this.setState({
			[isLoading]: true,
		});

		this.props.actions.user.updateUserProfile(userInfo).then((response) => {
			if (response.error) {
				this.setState({
					error: 'An error occurred while trying to update the user profile.',
					[isLoading]: false,
				});
			} else {
				this.setState({
					[isLoading]: false,
				});
			}
		})
			.catch((err) => {
				this.setState({
					error: `Error: ${err}`,
					[isLoading]: false,
				});
			});
	}

	updatePassword = (currentPassword, newPassword, confirmPassword) => {
		this.setState({
			isLoading: true,
			isChangePasswordLoading: true,
		});

		var passwordInfo = {
			currentPassword: currentPassword,
			newPassword: newPassword,
			confirmPassword: confirmPassword,
		}

		this.props.actions.user.updateUserPassword(passwordInfo)
			.then((response) => {
				if (response.error) {
					var errorMesssage = 'There was an error updating your password.';
					this.setState({
						error: errorMesssage,
						isChangePasswordLoading: false,
					});
				}
				else {
					this.setState({
						updateSuccess: true,
						isLoading: false,
						isChangePasswordLoading: false,
					});
				}
			})
			.catch((err) => {
				this.setState({
					error: "There was an error updating your password.",
					isChangePasswordLoading: false,
				});
			});
	}

	loadUserProfile = () => {
		this.setState({ isPageLoading: true })
		this.props.actions.user.getUserProfile()
			.then((response) => {
				if (response.error) {
					this.setState({
						error: 'An error occurred while trying to load the user profile.',
						isPageLoading: false,
					});
				} else {
					this.setState({ isPageLoading: false });
				}
			})
			.catch((err) => {
				this.setState({
					error: "Error",
					isPageLoading: false,
				});
			});
	}

	onUserInfoClick = (event) => {
		event.preventDefault();
		this.setState({
			isUserInfoOpen: !this.state.isUserInfoOpen,
			isSecretQuestionsOpen: false,
			isChangePasswordOpen: false
		});
	}

	onSecretQuestionsClick = (event) => {
		event.preventDefault();
		this.setState({
			isUserInfoOpen: false,
			isSecretQuestionsOpen: !this.state.isSecretQuestionsOpen,
			isChangePasswordOpen: false
		});
	}

	onChangePasswordClick = (event) => {
		event.preventDefault();
		this.setState({
			isUserInfoOpen: false,
			isSecretQuestionsOpen: false,
			isChangePasswordOpen: !this.state.isChangePasswordOpen
		});
	}

	componentDidMount() {
		this.loadUserProfile();
	}

	render() {
		let chevronRight = <FontAwesomeIcon style={{ "marginLeft": "10px" }} icon={faChevronRight} size="sm" />;
		let chevronDown = <FontAwesomeIcon style={{ "marginLeft": "10px" }} icon={faChevronDown} size="sm" />;;

		let chevronUserInfo = chevronRight;
		if (this.state.isUserInfoOpen) {
			chevronUserInfo = chevronDown;
		} else {
			chevronUserInfo = chevronRight;
		}

		let chevronSecretQuestions = chevronRight;
		if (this.state.isSecretQuestionsOpen) {
			chevronSecretQuestions = chevronDown;
		} else {
			chevronSecretQuestions = chevronRight;
		}

		let chevronChangePassword = chevronRight;
		if (this.state.isChangePasswordOpen) {
			chevronChangePassword = chevronDown;
		} else {
			chevronChangePassword = chevronRight;
		}

		return (
			<>
				{this.state.isPageLoading &&
					<LoadingIndicator />
				}
				{this.state.isPageLoading ? '' :
					<div>
						<h4>User Profile</h4>
						<div className="content">
							<Row className="g-0 sectionRow">
								<Col lg="12">
									<Card>
										<CardHeader className="cardHeader" onClick={this.onUserInfoClick}><h5>User Information {chevronUserInfo}</h5></CardHeader>
										<Collapse isOpen={this.state.isUserInfoOpen}>
											{this.state.isUserInfoLoading &&
												<LoadingIndicator />
											}
											{!this.state.isUserInfoLoading &&
												<ManageUserInfo details={this.props.details} onSubmit={this.saveUserProfile} />
											}
										</Collapse>
									</Card>
								</Col>
							</Row>
							<Row className="g-0 sectionRow">
								<Col lg="12">
									<Card>
										<CardHeader className="cardHeader" onClick={this.onSecretQuestionsClick}><h5>Manage Security Questions {chevronSecretQuestions}</h5></CardHeader>
										<Collapse isOpen={this.state.isSecretQuestionsOpen}>
											{this.state.isSecretQuestionsLoading &&
												<LoadingIndicator />
											}
											{!this.state.isSecretQuestionsLoading &&
												<ManageSecretQuestions details={this.props.details}
													onSubmit={this.saveSecretQuestions}
													questionLength={this.props.userInfoConfig.secretQuestion.questionLength}
													answerLength={this.props.userInfoConfig.secretQuestion.answerLength} />
											}
										</Collapse>
									</Card>
								</Col>
							</Row>
							<Row className="g-0 sectionRow">
								<Col lg="12">
									<Card>
										<CardHeader className="cardHeader" onClick={this.onChangePasswordClick}><h5>Change Password {chevronChangePassword}</h5></CardHeader>
										<Collapse isOpen={this.state.isChangePasswordOpen}>
											{this.state.isChangePasswordLoading &&
												<LoadingIndicator />
											}
											{!this.state.isChangePasswordLoading &&
												<ChangePassword
													onSubmit={this.updatePassword}
													response={this.props.changePasswordResponse}
													passwordConfig={this.props.passwordConfig}
												/>
											}
										</Collapse>
									</Card>
								</Col>
							</Row>
						</div>
					</div>
				}
			</>
		)
	}
}

function mapStateToProps(state, ownProps) {
	return {
		details: state.user.details,
		changePasswordResponse: state.user.changePassword,
		userInfoConfig: state.app.config.userInfo,
		passwordConfig: state.config.passwordConfig,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			user: bindActionCreators(userActions, dispatch)
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileView);
