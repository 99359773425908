import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import { TextInput } from './index';
import { validatePhoneNumber } from '../../lib/validation';

export default function PhoneNumberInput (props) {
	const { onChange, value, ...rest } = props;
	return <NumericFormat {...rest} value={value} customInput={TextInput} validate={validatePhoneNumber} onChange={onChange} format="###-###-####" mask="_"/>;
}

PhoneNumberInput.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	value: PropTypes.string,
}