import React, { useRef, useMemo, useCallback, useState, Key, useEffect, ReactNode } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { LicenseManager } from 'ag-grid-enterprise';
import { GridOptions, IServerSideDatasource } from 'ag-grid-community';
import { v4 as uuidv4 } from 'uuid';
import LoadingIndicator from 'components/loadingIndicator';
import { CustomHeaderSelectAll } from './customHeaderSelectAll';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './ag-theme-eh.css';
import { IGridColumn } from './types';
import { mapToAgGridColumns } from './gridColumnMapper';

LicenseManager.setLicenseKey(
	'Using_this_{AG_Grid}_Enterprise_key_{AG-060404}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Experian_(USA,_Hendersonville,_TE,_37075)}_is_granted_a_{Multiple_Applications}_Developer_License_for_{10}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_July_2025}____[v3]_[01]_MTc1MjQ0NzYwMDAwMA==61150d3d7a580ec2628ae06b1f12d18b',
);

interface DataGridProps<TData> {
	autoHeight?: boolean;
	columnDefs: IGridColumn[];
	customGridSearch?: ReactNode;
	data?: TData[];
	defaultPageSize?: number;
	defaultSort?: { colId: string; sort: 'asc' | 'desc' } | null;
	editable?: boolean;
	enableCellTextSelection?: boolean;
	enableColumnFilters?: boolean;
	gridHeading?: string;
	getRowClass?: (params: any) => string;
	getRowHeight?: any;
	height?: number;
	gridKey?: Key | null;
	noRowsMessage?: string;
	pagination?: boolean;
	resizable?: boolean;
	sortable?: boolean;
	setSelectedRows?: (selectedList: any[] | undefined) => void;
	serverSideDatasource?: IServerSideDatasource;
	suppressRowClickSelection?: boolean;
	rowSelection?: any;
	onRowClick?: (event: any, selectedRow: any) => void;
}

export function DataGrid<TData>({
	autoHeight = false,
	columnDefs,
	customGridSearch,
	data = [],
	defaultSort = null,
	defaultPageSize = 10,
	editable = false,
	enableCellTextSelection = true,
	enableColumnFilters = false,
	gridHeading,
	gridKey,
	getRowClass,
	getRowHeight,
	height = 200,
	noRowsMessage = 'There are no results',
	pagination = true,
	resizable = true,
	sortable = true,
	setSelectedRows,
	serverSideDatasource,
	suppressRowClickSelection = true,
	rowSelection = 'single',
	onRowClick,
}: DataGridProps<TData>) {
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const [pageSize, setPageSize] = useState(defaultPageSize);
	const gridRef = useRef<AgGridReact>(null);
	const heightValue = autoHeight ? 'auto' : height + 'px';
	const containerStyle = useMemo(() => ({ height: heightValue }), [heightValue]);

	const agColumnDefs = mapToAgGridColumns(columnDefs);

	const gridOptions = useMemo<GridOptions>(() => {
		const options: GridOptions = {
			components: {
				CustomHeaderSelectAll: CustomHeaderSelectAll,
			},
			defaultColDef: {
				resizable: resizable,
				sortable: sortable,
				editable: editable,
				filter: enableColumnFilters,
			},
			autoSizeStrategy: {
				type: 'fitCellContents'
			},
			domLayout: autoHeight ? 'autoHeight' : 'normal',
			getRowHeight: getRowHeight,
			loadingOverlayComponent: LoadingIndicator,
			noRowsOverlayComponent: NoRowsOverlay,
			noRowsOverlayComponentParams: { noRowsMessage: noRowsMessage },
			suppressPaginationPanel: !pagination,
			paginationPageSize: pageSize,
			paginationPageSizeSelector: [10, 25, 50, 100],
			suppressContextMenu: true,
			onGridReady: (params) => {
				if (isInitialLoad) {
					if (defaultSort) {
						const columnState = [
							{
								colId: defaultSort.colId,
								sort: defaultSort.sort,
							},
						];
						params.api.applyColumnState({ state: columnState });
					}
					setIsInitialLoad(false);
				}
			},
			...(serverSideDatasource && {
				cacheBlockSize: pageSize,
				maxBlocksInCache: 0,
				rowModelType: 'serverSide',
				getRowId: (params) => {
					// Ensure a unique ID is returned for each row
					return params.data ? params.data.id || uuidv4() : uuidv4();
				},
				serverSideDatasource: serverSideDatasource,
			}),
			getRowClass: getRowClass,
			loading: false,
			...(serverSideDatasource === undefined && { rowData: data }),
		};
		return options;
	}, [
		resizable,
		sortable,
		editable,
		enableColumnFilters,
		autoHeight,
		getRowClass,
		getRowHeight,
		noRowsMessage,
		pagination,
		pageSize,
		serverSideDatasource,
		data,
		isInitialLoad,
		defaultSort,
	]);

	const autoColumnResize = useCallback(() => {
		gridRef.current?.api.autoSizeAllColumns();
	}, [gridRef]);

	const onPaginationChanged = useCallback(() => {
		if (gridRef.current?.api) {
			const newPageSize = gridRef.current.api.paginationGetPageSize();
			if (newPageSize !== pageSize) {
				setPageSize(newPageSize);
				gridRef.current?.api.updateGridOptions({
					paginationPageSize: newPageSize,
					cacheBlockSize: newPageSize,
				});
				if (serverSideDatasource) {
					gridRef.current.api.refreshServerSide({ purge: true });
				}
			}
		}
	}, [pageSize, serverSideDatasource]);

	const onSelectionChanged = useCallback(() => {
		if (setSelectedRows) {
			const selectedRows = gridRef.current?.api.getSelectedRows();
			setSelectedRows(selectedRows);
		}
	}, [setSelectedRows]);

	const cellClicked = useCallback(
		(event: any) => {
			const colId = event.column.getId();
			if (!suppressRowClickSelection) {
				gridRef.current?.api.setGridOption("loading", true);
				if (colId !== 'button') {
					if (onRowClick) {
						const selectedRows = gridRef.current?.api.getSelectedRows();
						onRowClick(event.event, selectedRows?.[0]);
					}
				}
			}
		},
		[gridRef, onRowClick, suppressRowClickSelection]
	);

	useEffect(() => {
		if (gridRef.current && gridRef.current?.api && data && !serverSideDatasource) {
			gridRef.current?.api.applyTransaction({ update: data });
		}
	}, [data, serverSideDatasource]);

	return (
		<div className="eh-grid-container">
			{(gridHeading || customGridSearch) && (
				<div className="eh-grid-header">
					{gridHeading && <h4 className="eh-grid-heading">{gridHeading}</h4>}
					{customGridSearch && <div className="eh-grid-filter">{customGridSearch}</div>}
				</div>
			)}
			<div className="ag-theme-alpine ag-theme-eh" style={containerStyle}>
				<AgGridReact
					columnDefs={agColumnDefs}
					enableCellTextSelection={enableCellTextSelection}
					gridOptions={gridOptions}
					key={gridKey}
					onSelectionChanged={onSelectionChanged}
					pagination={pagination}
					paginationPageSize={pageSize}
					ref={gridRef}
					onCellClicked={(e) => cellClicked(e)}
					onGridSizeChanged={autoColumnResize}
					onFirstDataRendered={autoColumnResize}
					onPaginationChanged={onPaginationChanged}
					rowSelection={rowSelection}
					rowData={gridOptions.rowData}
				/>
			</div>
		</div>
	);
}

const NoRowsOverlay: React.FC<React.PropsWithChildren<{ noRowsMessage: string }>> = (props) => {
	return <strong className="ag-overlay-no-rows-center">{props.noRowsMessage}</strong>;
};
