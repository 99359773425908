import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'store';
import { verifyToken } from 'actions/authActions';
import LoadingIndicator from 'components/loadingIndicator';
import PermissionWarning from 'components/common/permissionWarning';
import { useHasPermission } from 'components/permissions/hooks';

interface ProtectedRouteProps {
	element: React.ReactNode;
	permission?: string | string[] | false;
}

export const ProtectedRoute: React.FC<React.PropsWithChildren<ProtectedRouteProps>> = ({ element: Element, permission }) => {
	const [loaded, setLoaded] = useState(false);

	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
	const isTermsOfUseAccepted = useAppSelector((state) => state.auth.isTermsOfUseAccepted);
	const compelPasswordChange = useAppSelector((state) => state.auth.compelPasswordChange);

	const hasPermission = useHasPermission(permission === false ? '' : permission ?? '') || permission === false;

	useEffect(() => {
		let unmounted = false;
		dispatch(verifyToken()).then(() => {
			if (!unmounted) setLoaded(true);
		});

		return () => {
			unmounted = true;
		};
	}, [dispatch]);

	useEffect(() => {
		if (loaded) {
			if (!isAuthenticated) {
				navigate('/login', { state: { from: location } });
				return;
			}

			if (isAuthenticated && (!isTermsOfUseAccepted || compelPasswordChange)) {
				navigate('/user/firstlogin', { state: { from: location } });
			}
		}
	}, [loaded, isAuthenticated, isTermsOfUseAccepted, compelPasswordChange, location, navigate]);

	if (!loaded) {
		return <LoadingIndicator />;
	}

	if (!hasPermission) {
		return <PermissionWarning />;
	}

	return <>{Element}</>;
};

export default ProtectedRoute;
