import * as actionTypes from './actionTypes';
import qs from 'qs';

export function getUserProfile() {
	return {
		type: actionTypes.USER_PROFILE_DETAILS,
		payload: {
			request: {
				method: 'get',
				url: `user/profile`
			}
		}
	};
}

export function updateUserProfile(updateUserProfileInfo) {
	return {
		type: actionTypes.USER_PROFILE_UPDATE,
		payload: {
			request: {
				method: 'put',
				url: `user/update`,
				data: {
					firstName: updateUserProfileInfo.firstName,
					lastName: updateUserProfileInfo.lastName,
					phone: updateUserProfileInfo.phone,
					secretQuestion1: updateUserProfileInfo.secretQuestion1,
					secretQuestion2: updateUserProfileInfo.secretQuestion2,
					secretQuestion3: updateUserProfileInfo.secretQuestion3,
					secretAnswer1: updateUserProfileInfo.secretAnswer1,
					secretAnswer2: updateUserProfileInfo.secretAnswer2,
					secretAnswer3: updateUserProfileInfo.secretAnswer3
				}
			}
		}
	};
}

export function updateUserPassword(updateUserPasswordInfo) {
	return {
		type: actionTypes.USER_PASSWORD_UPDATE,
		payload: {
			request: {
				method: 'put',
				url: `user/updatePassword`,
				data: {
					currentPassword: updateUserPasswordInfo.currentPassword,
					newPassword: updateUserPasswordInfo.newPassword,
					confirmPassword: updateUserPasswordInfo.confirmPassword,
				}
			}
		}
	};
}

export function forgotPassword(email) {
	return {
		type: actionTypes.USER_FORGOT_PASSWORD,
		payload: {
			request: {
				method: 'post',
				url: `user/forgotpassword`,
				data: {
					email: email,
				}
			}
		}
	};
}

export function checkUserProductInstance(email) {
	return {
		type: actionTypes.USER_PRODUCT_INSTANCE,
		payload: {
			request: {
				method: 'post',
				url: `user/${email}/getProductInstanceId`
			}
		}
	};
}

export function loadUserSecretQuestion(userId, token) {
	var params = qs.stringify({
		userId: userId,
		token: token,
	});
	return {
		type: actionTypes.USER_LOAD_SECRET_QUESTION,
		payload: {
			request: {
				method: 'get',
				url: `user/loadusersecretquestion?` + params,
			}
		}
	}
}

export function verifySecretAnswer(userId, secretQuestion, secretAnswer, exclude) {
	return {
		type: actionTypes.USER_VERIFY_SECRET_ANSWER,
		payload: {
			request: {
				method: 'post',
				url: `user/${userId}/verifysecretanswer`,
				data: {
					secretQuestion: secretQuestion,
					secretAnswer: secretAnswer,
					exclude: exclude,
				}
			}
		}
	}
}

export function resetPassword(userId, newPassword, confirmPassword, token) {
	return {
		type: actionTypes.USER_RESET_PASSWORD,
		payload: {
			request: {
				method: 'put',
				url: `user/${userId}/resetpassword`,
				data: {
					newPassword: newPassword,
					confirmPassword: confirmPassword,
					token: token,
				}
			}
		}
	}
}
