import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';

import { getPatientReferenceId } from '../../actions/patientActions';
import { patientDetails as routeToPatientDetails } from '../../routes';
import { PatientNotFound } from '../../components/NotFound';
import LoadingIndicator from '../../components/loadingIndicator';

export default function PatientRedirector() {
    const dispatch = useDispatch();
    const location = useLocation();

    const { refValue } = useParams();

    const [isLoading, setLoading] = useState(true);
    const config = useSelector((state) => state.config);

    const patientConfig = config.patient;
    const queryStringParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    useEffect(() => {
        if (patientConfig.allowExternalPatientDetailsUrl) {
            if (queryStringParams.refType && refValue) {
                dispatch(getPatientReferenceId(queryStringParams.refType, refValue)).then((response) => {
                    setLoading(false);
                    if (response.payload.data !== '') {
                        dispatch(routeToPatientDetails(response.payload.data));
                    }
                });
            } else {
                setLoading(false);
                if (refValue) {
                    dispatch(routeToPatientDetails(refValue.toLowerCase()));
                }
            }
        } else {
            setLoading(false);
        }
    }, [dispatch, patientConfig.allowExternalPatientDetailsUrl, refValue])

    return isLoading ? <LoadingIndicator /> : <PatientNotFound />;
}
