import React from 'react'
import PropTypes from 'prop-types'
import { PatternFormat } from 'react-number-format';
import TextInput from './textInput';
import moment from 'moment';

class DateInput extends React.Component {
	handleChange = (values) => {
		const { formattedValue } = values; 
		const { name } = this.props;
		const { validate, onChange } = this.props;

		var error = false;

		var date = moment(formattedValue, 'MM/DD/YYYY', true);
		if (!date.isValid() || (date.year() <= 1900 || date.year() >= 2100)) {
			error = true;
		} else {
			error = validate(formattedValue)
		}
		
		return onChange({ target: { name, value: formattedValue } }, error)
	}

	render() {
		const { id, name, value, type, className, validate, onChange, ...rest } = this.props

		return (
			<PatternFormat format="##/##/####" customInput={TextInput} id={id} name={name} type={type} onValueChange={this.handleChange} value={value} mask="_" className={className} {...rest} />
		)
	}
}

DateInput.propTypes = {
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	type: PropTypes.string.isRequired,
	validate: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.string])
}

DateInput.defaultProps = {
	validate: value => !(value && value.length)
}

export default DateInput
