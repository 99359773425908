import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

export default function MhdErrorDisplay(props) {
	return (
		<div className="error-display">
			<h2>
				Oops!  Admin Portal has encountered an error.
			</h2>
			Please <Button color="link" onClick={props.logout}>logout</Button> and/or contact MHD Support.
		</div>
	);
}

MhdErrorDisplay.propTypes = {
	logout: PropTypes.func.isRequired
};
