import { useState, useEffect, useCallback } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import qs from 'qs';
import * as authActions from '@/actions/authActions';
import LoadingIndicator from '@/components/loadingIndicator';
import { useAppDispatch, useAppSelector } from 'store';

const SingleSignOnRedirector = () => {
	const [isExchangingToken, setIsExchangingToken] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [params, setParams] = useState<any>(null);

	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const auth = useAppSelector((state) => state.auth);
	const { token } = useParams();

	const exchangeToken = useCallback(
		(token: string) => {
			setIsExchangingToken(true);
			dispatch(authActions.exchangeToken(token))
				.then((response: any) => {
					if (response.error) {
						setError('An error occurred while trying to exchange token.');
					} else {
						setIsExchangingToken(false);
					}
				})
				.catch(() => {
					setError('An error occurred while trying to exchange token.');
				});
		},
		[dispatch],
	);

	useEffect(() => {
		const parsedParams = qs.parse(location.search, { ignoreQueryPrefix: true });
		setParams(parsedParams);
		if (token) {
			exchangeToken(token);
		}
	}, [location.search, token, exchangeToken, setParams]);

	useEffect(() => {
		if (auth.isAuthenticated && params) {
			const returnUrl = params.returnUrl || '/';
			navigate(returnUrl);
		}
	}, [auth.isAuthenticated, params, navigate]);

	return (
		<div>
			{isExchangingToken && <LoadingIndicator loadingMessage="Processing Login..." />}
			{error && <div>{error}</div>}
		</div>
	);
};

export default SingleSignOnRedirector;
