import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface UseAppHistory {
	navigate: (path: string, state?: Record<string, any>) => void;
	goBack: () => void;
	refresh: () => void;
}

export const useAppHistory = (): UseAppHistory => {
	const navigate = useNavigate();

	const navigateWithDelay = useCallback(
		(path: string, state: Record<string, any> = {}) => {
			setTimeout(() => {
				navigate(path, { state });
			}, 100);
		},
		[navigate],
	);

	const goBack = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const refresh = useCallback(() => {
		navigate(0);
	}, [navigate]);

	return {
		navigate: navigateWithDelay,
		goBack,
		refresh,
	};
};

export default useAppHistory;