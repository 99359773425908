import * as types from '../actions/actionTypes'

const initialState = {
	details: {},
	isLockedOut: false,
	changePassword: {
		succeeded: false,
		errors: [],
	},
	forgotPasswordAction: {
		succeeded: false,
	},
	verifiedResult: {
		email: '',
		error: '',
		isVerified: false,
	},
	secretQuestion: {
		questionText: '',
		questionNumber: null,
	},
	secretAnswer: {
		isAnswerCorrect: false,
	},
	resetPassword: {
		succeeded: false,
		errors: [],
	},
	userProductInstanceId: 0
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.USER_PROFILE_UPDATE_SUCCESS:
			return {
				...state,
				details: action.payload.data.userProfileDetails,
			}
		case types.USER_PROFILE_DETAILS_SUCCESS:
			return {
				...state,
				details: action.payload.data,
			}
		case types.USER_PASSWORD_UPDATE_SUCCESS:
			return {
				...state,
				changePassword: {
					succeeded: action.payload.data.succeeded,
					errors: action.payload.data.errors,
				}
			}
		case types.USER_FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				forgotPasswordAction: {
					succeeded: true,
				}
			}
		case types.USER_PRODUCT_INSTANCE_SUCCESS:
			return {
				...state,
				userProductInstanceId: action.payload.data,
			}
		case types.USER_LOAD_SECRET_QUESTION_SUCCESS:
			return {
				...state,
				isLockedOut: action.payload.data.isLockedOut,
				verifiedResult: {
					email: action.payload.data.email,
					error: action.payload.data.error,
					isVerified: action.payload.data.isVerified,
				},
				secretQuestion: {
					questionNumber: action.payload.data.secretQuestionResult ? action.payload.data.secretQuestionResult.questionNumber : null,
					questionText: action.payload.data.secretQuestionResult ? action.payload.data.secretQuestionResult.questionText : null,
				},
			}
		case types.USER_VERIFY_SECRET_ANSWER_SUCCESS:
			return {
				...state,
				isLockedOut: action.payload.data.isUserLockedOut,
				secretQuestion: {
					questionNumber: action.payload.data.secretQuestionResult ? action.payload.data.secretQuestionResult.questionNumber : null,
					questionText: action.payload.data.secretQuestionResult ? action.payload.data.secretQuestionResult.questionText : null,
				},
				secretAnswer: {					
					isAnswerCorrect: action.payload.data.isAnswerCorrect,
				}
			}
		case types.USER_RESET_PASSWORD_SUCCESS:
			return {
				...state,
				resetPassword: {
					succeeded: action.payload.data.succeeded,
					errors: action.payload.data.errors,
				}
			}			
		default:
			return state;
	}
}
