/**
 * @format
 */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as routes from '../../routes';
import { Button, Col, Row, Alert } from 'reactstrap';
import PreferredContactPhone from '../../components/patient/preferredContactPhone';
import { scrollToPageTop } from '../../lib/misc';
import { displayFullName } from '../../lib/standardLibrary';
import { validateField } from '../../lib/validation';
import LoadingIndicator from '../../components/loadingIndicator';
import * as appointmentActions from '../../actions/appointmentActions';
import * as rd2RefactorActions from '../../actions/rd2RefactorActions';
import * as providerActions from '../../actions/providerActions';
// import PatientDetailsHeader from '../../components/patient/patientDetailsHeader';
import PatientInfoTabbedCollapsable from 'features/patient/comps/patientInfoTabbedCollapsable';
import Attachments from '../../components/appointment/attachments';
import CustomFields from '../../components/appointment/customFields';
import PatientNotifications from '../../components/appointment/patientNotifications';
import ReasonForVisit from '../../components/appointment/reasonForVisit';
import SchedulingInformation from '../../components/appointment/schedulingInformation';
import CalendarDetails from '../../components/calendar/calendarDetails';
import './requestAppointmentView.css';
import { withRouter } from 'components/hoc';

export class RequestAppointmentView extends React.Component {
	constructor(props) {
		super(props);
		const params = this.props.params;

		this.state = {
			error: '',
			collapse: {
				emailRequestConsent: false,
				emailConsent: true,
				email: false,
				text: false,
				phone: false,
			},
			isCustomFieldsLoading: true,
			isDetailsLoading: true,
			isRequesting: false,
			calendarDetails: {},
			fieldValidation: {
				reasonForVisit: {
					dataType: 'String',
					valid: true,
					max: this.props.schedulingConfig.reasonForVisitCharacterLimit,
					required: this.props.schedulingConfig.reasonForVisit.isRequired,
				},
				schedulingInformation: {
					dataType: 'String',
					valid: true,
					max: this.props.schedulingConfig.reasonForVisitCharacterLimit,
					required: false,
				},
				confirmationEmailOther: { dataType: 'Email', valid: true, required: false },
				reminderEmailOther: { dataType: 'Email', valid: true, required: false },
				emailReminderLeadTime: {
					dataType: 'Int32',
					valid: true,
					required: false,
					min: this.props.notificationConfig.minEmailReminderLeadTime,
				},
				voiceReminderNumber: { dataType: 'Phone', valid: true, required: false },
				voiceReminderOtherNumber: { dataType: 'Phone', valid: true, required: false },
				voiceReminderLeadTime: {
					dataType: 'Int32',
					valid: true,
					required: false,
					min: this.props.notificationConfig.minVoiceReminderLeadTime,
				},
				textReminderNumber: { dataType: 'Phone', valid: true, required: false },
				textReminderOtherNumber: { dataType: 'Phone', valid: true, required: false },
				textReminderLeadTime: {
					dataType: 'Int32',
					valid: true,
					required: false,
					min: this.props.notificationConfig.minTextReminderLeadTime,
				},
				selectedLanguage: { dataType: 'Int32', valid: true, required: false },
			},
			formValidation: {
				voiceReminderLeadTime: { valid: true, required: false },
				voiceReminderNumber: { valid: true, required: false },
				voiceReminderOtherNumber: { valid: true, required: false },
				textReminderLeadTime: { valid: true, required: false },
				textReminderNumber: { valid: true, required: false },
				textReminderOtherNumber: { valid: true, required: false },
			},
			isPageValid: true,
			reasonForVisit: this.props.symptomDetails.reasonForVisit ? this.props.symptomDetails.reasonForVisit : '',
			selectedLanguage: this.props.notificationConfig.languageId,
			fileError: null,
			files: [],
			customFieldAnswers: null,
			customFieldValidation: null,
			confirmationEmailConsent: false,
			confirmationEmail: '',
			confirmationEmailOther: '', 
			reminderEmail: '',
			reminderEmailOther: '',
			emailReminderConsent: false,
			emailReminderLeadTime: this.props.notificationConfig.minEmailReminderLeadTime,
			voiceReminderConsent: false,
			voiceReminderNumber: '',
			voiceReminderOtherNumber: '',
			voiceReminderLeadTime: this.props.notificationConfig.minVoiceReminderLeadTime,
			disableVoiceReminderNumber: false,
			disableVoiceReminderOtherNumber: false,
			textReminderConsent: false,
			textReminderNumber: '',
			textReminderOtherNumber: '',
			textReminderLeadTime: this.props.notificationConfig.minTextReminderLeadTime,
			disableTextReminderNumber: false,
			disableTextReminderOtherNumber: false,
			serviceSiteId: params.serviceSiteId,
			selectedContactPhone: '',
			selectedContactPhoneType: '',
			schedulingInformation: '',
			shouldSendRequestConfirmationEmail: false,
			alternateConfirmationEmail: '',
			isPatientHeaderCollapsed: true,
			showOtherInput: false,
			phones: [],
			otherPhoneInput: '',
		};
	}

	patientHeaderToggle = () => {
		this.setState({ isPatientHeaderCollapsed: !this.state.isPatientHeaderCollapsed });
	};

	loadProviderDetails = () => {
		this.setState({ isDetailsLoading: true }, () => {
			this.props.actions.provider
				.getProviderByServiceSiteId(this.state.serviceSiteId)
				.then((response) => {
					if (response.error) {
						this.setState({ error: 'An error occurred while trying to get provider details.' });
					}
					let providerDetails = response.payload.data;
					let calendarDetails = this.mapProviderDetailsToCalendarDetails(providerDetails);
					this.setState({ isDetailsLoading: false, calendarDetails });
				})
				.catch((err) => {
					this.setState({ error: 'An error occurred while trying to get provider details.' });
				});
		});
	};

	loadCustomFields = () => {
		this.props.actions.appointment
			.getCustomFields(this.props.referralSystemId)
			.then((response) => {
				if (response.error) {
					this.setState({
						error: 'An error occurred while trying to get custom fields.',
						isCustomFieldsLoading: false,
					});
				} else {
					let { customFields } = this.props;
					let answers = {};
					let validation = {};
					customFields.customFields.forEach((field) => {
						// Look for matching DS subpoints to prepopulate values
						if (customFields.customFieldAnswers.length) {
							let answerMatch = customFields.customFieldAnswers.find((answer) => {
								return answer.key.localeCompare(field.fieldLabel, undefined, { sensitivity: 'accent' }) === 0;
							});
							answers[field.fieldName] = answerMatch ? answerMatch.value : '';
						} else {
							answers[field.fieldName] = '';
						}

						validation[field.fieldName] = {
							valid: true,
							required: field.isRequired,
							active: field.isActive,
							dataType: field.dataType,
							public: field.printIt,
							isPostBookAppointment: field.isPostBookAppointment,
						};
					});
					this.setState(
						{
							isCustomFieldsLoading: false,
							customFieldAnswers: answers,
							customFieldValidation: validation,
						},
						() => {
							this.initializePatientNotifications();
						},
					);
				}
			})
			.catch((err) => {
				this.setState({
					error: 'An error occurred while trying to get custom fields.',
					isPageLoading: false,
				});
			});
	};

	initializePatientNotifications = () => {
		if (this.props.emailConfirmationEnabled && !this.props.enableConfirmationEmailOptIn) {
			this.togglePatientNotifications('emailConsent');
			let event = {
				target: {
					name: 'confirmationEmailConsent',
					checked: true,
				},
			};

			this.handleCheckboxChange(
				event,
				this.props.patientDetails.email && this.props.patientDetails.email.trim() !== ''
					? []
					: ['confirmationEmailOther'],
			);
			let fieldValidation = { ...this.state.fieldValidation };
			fieldValidation.confirmationEmailOther.valid =
				this.props.patientDetails.email && this.props.patientDetails.email.trim() !== '' ? true : false;
			fieldValidation.confirmationEmailOther.required =
				this.props.patientDetails.email && this.props.patientDetails.email.trim() !== '' ? false : true;
			this.setState({
				enableConfirmationEmailInstructions: false,
				fieldValidation,
			});
		}
	};

	togglePatientNotifications = (area) => {
		let col = { ...this.state.collapse };
		col[area] = !col[area];
		this.setState({ collapse: col });
	};

	handleChange = (event, type) => {
		const { name, value, min, max } = event.target;
		let valObj = { ...this.state.fieldValidation };
		if (valObj[name]) {
			let isRequired = valObj[name].required;
			if (value && value.trim() !== '') {
				isRequired = true;
			}
			this.setState({ [name]: value, fieldValidation: valObj }, () => {
				valObj[name].valid = this.validateFieldAndForm(name, type, value, min, max, isRequired);
			});
		}
	};

	handleCheckboxChange = (event, requiredFields) => {
		const { name, checked } = event.target;
		this.setState({ [name]: checked });
		let valObj = { ...this.state.fieldValidation };
		let formVal = { ...this.state.formValidation };
		requiredFields.forEach((field) => {
			//fields only required when the checkbox is checked
			if (formVal[field]) {
				formVal[field].required = checked;
				this.setState({ formValidation: formVal });
			}
			if (valObj[field]) {
				valObj[field].required = checked;
				let { dataType, min, max } = { ...valObj[field] };
				this.validateFieldAndForm(field, dataType, this.state[field], min, max, valObj[field].required);
				this.setState({ fieldValidation: valObj });
			}
		});
	};

	handleCustomFieldChange = (event, type) => {
		const { name, value, min, max } = event.target;
		let newObj = { ...this.state.customFieldAnswers };
		this.validateFieldAndForm(name, type, value, min, max, this.state.customFieldValidation[name].required, true);
		newObj[name] = value;
		this.setState({ customFieldAnswers: newObj });
	};

	handleCustomFieldDropdownChange = (name, obj) => {
		let newObj = { ...this.state.customFieldAnswers };
		let value = obj ? obj.value : null;
		this.validateFieldAndForm(
			name,
			this.state.customFieldValidation[name].dataType,
			value,
			null,
			null,
			this.state.customFieldValidation[name].required,
			true,
		);
		newObj[name] = value;
		this.setState({ customFieldAnswers: newObj });
	};

	handleCustomFieldDateChange = (event, type) => {
		const { name, value, min, max } = event.target;
		let newObj = { ...this.state.customFieldAnswers };
		if (value === '__/__/____' || value === '') return;
		newObj[name] = value;
		this.validateFieldAndForm(name, type, value, min, max, this.state.customFieldValidation[name].required, true);
		this.setState({ customFieldAnswers: newObj });
	};

	handleServerTypeAheadSearch = (query) => {
		this.setState({ isServerTypeAheadLoading: true });

		this.props.actions.searchReferringProviders(this.props.referralSystemId, query).then(() => {
			this.setState({ isServerTypeAheadLoading: false });
		});
	};

	handleDropdownChange = (name, type, obj) => {
		let valObj = { ...this.state.fieldValidation };
		if (obj) {
			this.setState({ [name]: obj.value }, () => {
				this.validateFieldAndForm(name, type, obj.value, valObj[name].min, valObj[name].max);
			});
		} else {
			this.setState({ [name]: '' }, () => {
				this.validateFieldAndForm(name, type, '');
			});
		}
	};

	validate = () => {
		let validation = this.state.customFieldValidation;

		const preBook = {};
		const postBook = {};

		for (let key in validation) {
			if (validation[key].isPostBookAppointment === false) preBook[key] = validation[key];
			else postBook[key] = validation[key];
		}

		//re-run validations
		let customFieldValidity = Object.keys(preBook).every((field) => {
			let valField = this.state.customFieldValidation[field];
			return this.validateFieldAndForm(
				field,
				valField.dataType,
				this.state.customFieldAnswers[field],
				null,
				null,
				valField.required,
				true,
			);
		});

		let fieldValidityArray = [];
		Object.keys(this.state.fieldValidation).forEach((field) => {
			let valField = this.state.fieldValidation[field];
			fieldValidityArray.push(
				this.validateFieldAndForm(
					field,
					valField.dataType,
					this.state[field],
					(valField.min = null),
					(valField.max = null),
					valField.required,
				),
			);
		});
		let fieldValidity = fieldValidityArray.every((x) => x === true);

		let formValidityArray = [];
		Object.keys(this.state.formValidation).forEach((field) => {
			formValidityArray.push(this.validateForm(field));
		});
		let formValidity = formValidityArray.every((x) => x === true);

		this.setState({ isPageValid: fieldValidity && customFieldValidity && formValidity });
		return fieldValidity && customFieldValidity && formValidity;
	};

	validateForm = (name) => {
		let isValid = true;
		//TODO: Find a way to combine some of these similar validation functions
		switch (name) {
			case 'voiceReminderLeadTime':
			case 'textReminderLeadTime':
				isValid = true;
				break;
			case 'voiceReminderOtherNumber':
			case 'voiceReminderNumber':
				isValid = true;
				break;
			case 'textReminderOtherNumber':
			case 'textReminderNumber':
				isValid = true;
				break;
			default:
				break;
		}
		return isValid;
	};

	isVoiceReminderNumberValid = (name) => {
		let isValid = false;
		let formValidation = { ...this.state.formValidation };
		let fieldValidation = { ...this.state.fieldValidation };
		let { voiceReminderOtherNumber, disableVoiceReminderOtherNumber, disableVoiceReminderNumber } = { ...this.state };

		if (formValidation[name].required) {
			//default values for first load
			formValidation.voiceReminderNumber.required = true;
			formValidation.voiceReminderNumber.valid = false;
			fieldValidation.voiceReminderOtherNumber.required = true;
			formValidation.voiceReminderOtherNumber.valid = false;

			disableVoiceReminderNumber = false;
			disableVoiceReminderOtherNumber = false;

			if (this.state.voiceReminderNumber) {
				fieldValidation.voiceReminderOtherNumber.required = false;
				fieldValidation.voiceReminderOtherNumber.valid = true;
				formValidation.voiceReminderOtherNumber.valid = true;
				formValidation.voiceReminderNumber.valid = fieldValidation.voiceReminderNumber.valid;
				isValid = formValidation.voiceReminderNumber.valid;

				voiceReminderOtherNumber = '';
				disableVoiceReminderOtherNumber = true;
			} else if (this.state.voiceReminderOtherNumber) {
				fieldValidation.voiceReminderNumber.required = false;
				fieldValidation.voiceReminderNumber.valid = true;
				formValidation.voiceReminderNumber.required = false;
				formValidation.voiceReminderNumber.valid = true;
				disableVoiceReminderNumber = true;
				formValidation.voiceReminderOtherNumber.valid = fieldValidation.voiceReminderOtherNumber.valid;
				isValid = fieldValidation.voiceReminderOtherNumber.valid;
			}

			formValidation[name].valid = isValid;
		} else {
			formValidation[name].valid = true;
			isValid = true;
		}
		this.setState({
			formValidation,
			fieldValidation,
			voiceReminderOtherNumber,
			disableVoiceReminderOtherNumber,
			disableVoiceReminderNumber,
		});
		return isValid;
	};

	isTextReminderNumberValid = (name) => {
		let isValid = false;
		let formValidation = { ...this.state.formValidation };
		let fieldValidation = { ...this.state.fieldValidation };
		let { textReminderOtherNumber, disableTextReminderOtherNumber, disableTextReminderNumber } = { ...this.state };

		if (formValidation[name].required) {
			//default values for first load
			formValidation.textReminderNumber.required = true;
			formValidation.textReminderNumber.valid = false;
			fieldValidation.textReminderOtherNumber.required = true;
			formValidation.textReminderOtherNumber.valid = false;

			disableTextReminderNumber = false;
			disableTextReminderOtherNumber = false;

			if (this.state.textReminderNumber) {
				fieldValidation.textReminderOtherNumber.required = false;
				fieldValidation.textReminderOtherNumber.valid = true;
				formValidation.textReminderOtherNumber.valid = true;
				formValidation.textReminderNumber.valid = fieldValidation.textReminderNumber.valid;
				isValid = formValidation.textReminderNumber.valid;

				textReminderOtherNumber = '';
				disableTextReminderOtherNumber = true;
			} else if (this.state.textReminderOtherNumber) {
				fieldValidation.textReminderNumber.required = false;
				fieldValidation.textReminderNumber.valid = true;
				formValidation.textReminderNumber.required = false;
				formValidation.textReminderNumber.valid = true;
				disableTextReminderNumber = true;
				formValidation.textReminderOtherNumber.valid = fieldValidation.textReminderOtherNumber.valid;
				isValid = fieldValidation.textReminderOtherNumber.valid;
			}

			formValidation[name].valid = isValid;
		} else {
			formValidation[name].valid = true;
			isValid = true;
		}
		this.setState({
			formValidation,
			fieldValidation,
			textReminderOtherNumber,
			disableTextReminderOtherNumber,
			disableTextReminderNumber,
		});
		return isValid;
	};

	determineCustomFieldValidity = (isPreBook) => {
		let validation = this.state.customFieldValidation;
		const preBookCustomFields = {};
		const postBookCustomFields = {};
		for (let key in validation) {
			if (validation[key].isPostBookAppointment === false) preBookCustomFields[key] = validation[key];
			else postBookCustomFields[key] = validation[key];
		}

		let customFieldValidity;

		if (isPreBook === true) {
			customFieldValidity = Object.keys(preBookCustomFields).every((field) => {
				return preBookCustomFields[field].valid === true;
			});
		} else {
			customFieldValidity = Object.keys(postBookCustomFields).every((field) => {
				return postBookCustomFields[field].valid === true;
			});
		}

		return customFieldValidity;
	};

	validateFieldAndForm = (
		name,
		type,
		value,
		minimum = null,
		maximum = null,
		required = true,
		isCustomField = false,
	) => {
		let isFieldValid = validateField(name, type, value, minimum, maximum, required);

		if (isCustomField) {
			let valObj = { ...this.state.customFieldValidation };
			valObj[name].valid = isFieldValid;
			this.setState({ customFieldValidation: valObj });
		} else {
			let valObj = { ...this.state.fieldValidation };
			if (valObj[name]) {
				valObj[name].valid = isFieldValid;
				this.setState({ fieldValidation: valObj });
			}
		}

		this.validateForm(name);

		let formValidity = Object.keys(this.state.formValidation).every((field) => {
			return this.state.formValidation[field].valid === true;
		});

		let validity = Object.keys(this.state.fieldValidation).every((field) => {
			return this.state.fieldValidation[field].valid === true;
		});

		let customFieldValidity = this.determineCustomFieldValidity(true);

		this.setState({ isPageValid: validity & customFieldValidity & formValidity ? true : false });

		return isFieldValid;
	};

	onDrop = (acceptedFiles, rejectedFiles) => {
		if (this.state.files.length + acceptedFiles.length > this.props.systemConfig.appointmentMaxAttachments) {
			this.setState({
				fileError: 'Only ' + this.props.systemConfig.appointmentMaxAttachments + ' attachments may be added.',
			});
			return;
		}
		acceptedFiles.forEach((file) => {
			const reader = new FileReader();
			reader.onload = (e) => {
				let fileAsBinaryString = '';

				if (!e) {
					fileAsBinaryString = reader.content;
				} else {
					fileAsBinaryString = reader.result;
				}

				let fileArray = this.state.files.slice();
				fileArray.push({ name: file.name, size: file.size, data: fileAsBinaryString });
				this.setState({ files: fileArray, fileError: null });
			};
			reader.onabort = () => console.log('file reading was aborted');
			reader.onerror = () => console.log('file reading has failed');

			reader.readAsBinaryString(file);
		});
	};

	onDropRejected = () => {
		this.setState({
			fileError: `Files exceed�${this.props.schedulingConfig.maxAppointmentAttachmentSize / 1048576
				}MB limit or file extensions are not valid. Please attach again.`,
		});
	};

	onCancel = () => {
		this.setState({
			files: [],
			fileError: null,
		});
	};

	removeFile = (f) => {
		let fileArray = this.state.files.slice();
		fileArray.splice(fileArray.indexOf(f), 1);
		this.setState({
			files: fileArray,
			fileError: null,
		});
	};

	submitAppointmentRequest() {
		if (this.validate()) {
			this.setState({ isRequesting: true });
			scrollToPageTop();

			let cfa = this.props.customFields.customFields.map((field) => {
				return { Label: field.fieldName, Value: this.state.customFieldAnswers[field.fieldName] };
			});

			let attachments = this.state.files.map((file) => {
				return { fileName: file.name, base64EncodedFileContent: window.btoa(file.data) };
			});

			let serviceName = `${this.props.providerDetails.firstName} ${this.props.providerDetails.lastName}`;
			let specialtyName =
				this.props.availability.searchContext.calendarSearchCriteria.providerCriteria?.specialtyName ??
				this.props.availability.searchContext.calendarSearchCriteria.providerCriteria.specialtyName;

			let requestInfo = {
				patientReferenceId: this.props.patientReferenceId,
				referralSystemId: this.props.auth.referralSystemId,
				memberId: this.props.patientDetails.memberId,
				idUserReferrer: this.props.auth.userId,
				idPgmPayorType: this.props.availability.searchContext.calendarSearchCriteria.patientCriteria?.payorType
					? this.props.availability.searchContext.calendarSearchCriteria.patientCriteria?.payorType
					: null,
				idPgmSpecialty:
					specialtyName ??
					this.props.availabilitySearchSupportData.specialtyList.find((x) => x.pgmSpecialtyName === specialtyName)
						?.idPgmSpecialty,
				idLanguage: this.state.selectedLanguage,
				reasonForReferral: this.state.reasonForVisit,
				siteId: this.props.referralSiteId,
				referringServiceName: '',
				idInsurance: this.props.availability.searchContext.calendarSearchCriteria.patientCriteria?.insuranceProviderId
					? this.props.availability.searchContext.calendarSearchCriteria.patientCriteria?.insuranceProviderId
					: null,
				serviceName: serviceName,
				idServiceSite: this.props.providerDetails.calendarId,
				patientMessage: '',
				uploadedFiles: attachments,
				preferredContactPhone: this.state.selectedContactPhone,
				preferredContactPhoneType: this.state.selectedContactPhoneType,
				schedulingInformation: this.state.schedulingInformation,
				shouldSendConfirmationEmail: this.state.shouldSendRequestConfirmationEmail,
				alternateConfirmationEmail: this.state.alternateConfirmationEmail,
				customValues: cfa,
				patientNotificationLanguageCode: this.state.selectedLanguage && this.state.selectedLanguage === 2 ? 'es' : 'en',

				patientConfirmationEmailEnabled: this.state.confirmationEmailConsent,
				patientConfirmationEmailDestinationEmail: this.state.confirmationEmailOther
					? this.state.confirmationEmailOther
					: this.props.patientDetails.email,
				patientReminderSmsEnabled: this.state.textReminderConsent,
				patientReminderSmsLeadTimeHours: this.state.textReminderLeadTime,
				patientReminderSmsDestinationPhone: this.state.textReminderOtherNumber
					? this.state.textReminderOtherNumber
					: this.state.textReminderNumber,
				patientReminderVoiceEnabled: this.state.voiceReminderConsent,
				patientReminderVoiceLeadTimeHours: this.state.voiceReminderLeadTime,
				patientReminderVoiceDestinationPhone: this.state.voiceReminderOtherNumber
					? this.state.voiceReminderOtherNumber
					: this.props.patientDetails.homePhone,
				patientReminderEmailEnabled: this.state.emailReminderConsent,
				patientReminderEmailLeadTimeHours: this.state.emailReminderLeadTime,
				patientReminderEmailDestinationEmail: this.state.confirmationEmailOther
					? this.state.confirmationEmailOther
					: this.props.patientDetails.email,
			};

			this.props.actions.appointment
				.requestAppointment(this.props.token, requestInfo)
				.then((response) => {
					if (response.error) {
						this.setState({
							error: 'An error occurred while trying to request an appointment.',
							isRequesting: false,
						});
					} else {
						this.props.dispatch(routes.requestAppointmentDetails(response.payload.data.requestAppointmentId));
						this.setState({
							isRequesting: false,
						});
					}
				})
				.catch((err) => {
					this.setState({
						error: 'An error occurred while trying to request an appointment.',
						isRequesting: false,
					});
				});
		}
	}

	onCancelClick = () => {
		this.props.history.goBack();
	};

	onSubmitRequestClick = () => {
		this.submitAppointmentRequest();
	};

	goBack = () => {
		this.props.history.goBack();
	};

	componentDidMount() {
		if (!this.props.patientReferenceId) {
			this.goBack();
		}

		let phones = [];
		this.props.patientDetails.homePhone && phones.push({ type: 'Home', number: this.props.patientDetails.homePhone });
		this.props.patientDetails.mobilePhone &&
			phones.push({ type: 'Mobile', number: this.props.patientDetails.mobilePhone });
		this.props.patientDetails.alternatePhone &&
			phones.push({ type: 'Alternate', number: this.props.patientDetails.alternatePhone });
		this.setState({ phones });

		if (phones.length > 0) {
			let defaultPhone = phones[0];
			this.setState({
				selectedContactPhone: defaultPhone.number,
				selectedContactPhoneType: defaultPhone.type,
			});
		}
		this.loadProviderDetails();
		this.loadCustomFields();

		if (
			this.props.patientConfig.notes.isVisible &&
			this.props.schedulingConfig.autoExpandPatientHeaderForVisibleNotes
		) {
			this.setState({ isPatientHeaderCollapsed: false });
		}
	}

	selectPreferredContact = (e) => {
		e.preventDefault();
		const { value } = e.target;

		if (value !== 'Other') {
			let number = this.state.phones.find((x) => x.type === value).number;
			this.setState({
				selectedContactPhoneType: value,
				selectedContactPhone: number,
				showOtherInput: false,
			});
		} else {
			this.setState({
				selectedContactPhoneType: value,
				showOtherInput: true,
			});
		}
	};

	inputOtherPhone = (e) => {
		e.preventDefault();
		const { value } = e.target;
		this.setState({
			otherPhoneInput: value,
			selectedContactPhone: value,
		});
	};

	resolveConfirmationEmailInstructions = () => {
		let result = 'No email address in patient profile.';

		let { patientDetails } = this.props;
		let isAutomatic = false;
		let automaticallyText = isAutomatic ? 'automatically ' : '';

		if (patientDetails.email && patientDetails.email.trim() !== '') {
			result = `Email Appointment Confirmation will ${automaticallyText}be sent to ${patientDetails.email}. Optionally, you may override that email below.`;
		}
		return result;
	};

	mapProviderDetailsToCalendarDetails = (provider) => {
		let serviceName = displayFullName(provider.firstName, provider.lastName);
		serviceName = `${serviceName}${provider.professionalDesignation && provider.professionalDesignation.trim() !== ''
			? `, ${provider.professionalDesignation}`
			: ''
			}`;

		let calendarDetails = {
			...provider,
			address1: provider.addressLine1,
			address2: provider.addressLine2,
			serviceName: serviceName,
			state: provider.stateCode,
			zipCode: provider.zip,
			imageFile: provider.image,
			genderCode: provider.gender,
			specialtyName: provider.specialty,
		};

		return calendarDetails;
	};

	render() {
		let shownCustomFields = this.props.customFields.customFields.filter(
			(field) => field.printIt && !field.isPostBookAppointment,
		);

		let disableSubmitButton = this.state.isDetailsLoading || this.state.isRequesting || !this.state.isPageValid;

		let headerText = 'Request Appointment';
		let instructionsText =
			'Please click on the "Submit Appointment Request" button at the bottom of the page to submit your appointment request based on the details below. Click on the "Cancel" button if you need go back.';
		let attachmentsLabel = this.props.schedulingConfig.enableAddAttachments ? 'Add Attachments' : '';
		let reasonForVisitLabel = this.props.schedulingConfig.reasonForVisit.isRequired
			? `${this.props.schedulingConfig.reasonForVisit.fieldLabel} (required)`
			: this.props.schedulingConfig.reasonForVisit.fieldLabel;
		let schedulingInformationLabel = <h6 style={{ marginBottom: '15px', marginTop: '5px' }}>Scheduling Information</h6>;
		let patientNotificationsLabel = 'Configure Patient Notifications';
		let confirmationEmailInstructions = this.resolveConfirmationEmailInstructions();

		return (
			<div className="container-fluid">
				<div>{this.state.error && <Alert color="danger">{this.state.error}</Alert>}</div>
				{this.state.isDetailsLoading || this.state.isCustomFieldsLoading || this.state.isRequesting ? (
					<LoadingIndicator />
				) : (
					<div>
						<div>
							<h2>{headerText}</h2>
							<p>{instructionsText}</p>
						</div>
						<CalendarDetails
							calendarDetails={this.state.calendarDetails}
							isRequestAppointment={true}
							isRescheduleFlow={false}
							onCancel={this.goBack}
							providerFieldConfig={this.props.providerFieldConfig}
							showNpi={false}
							isLoading={this.props.isLoadingSearchState}
						/>
						{this.props.patientReferenceId && this.props.patientReferenceId !== '' && (
							<PatientInfoTabbedCollapsable
								isOpen={!this.state.isPatientHeaderCollapsed}
								toggleIsOpen={this.patientHeaderToggle}
							/>
						)}
						<PreferredContactPhone
							inputOtherPhone={this.inputOtherPhone}
							otherPhone={this.state.otherPhoneInput}
							phones={this.state.phones}
							selectPreferredContact={this.selectPreferredContact}
							showOtherInput={this.state.showOtherInput}
						/>
						<PatientNotifications
							collapse={this.state.collapse}
							config={this.props.notificationConfig}
							confirmationEmailConsent={this.state.confirmationEmailConsent}
							confirmationEmailInstructions={confirmationEmailInstructions}
							confirmationEmailOther={this.state.confirmationEmailOther}
							disableTextReminderNumber={this.state.disableTextReminderNumber}
							disableTextReminderOtherNumber={this.state.disableTextReminderOtherNumber}
							disableVoiceReminderNumber={this.state.disableVoiceReminderNumber}
							disableVoiceReminderOtherNumber={this.state.disableVoiceReminderOtherNumber}
							emailReminderConsent={this.state.emailReminderConsent}
							emailReminderLeadTime={this.state.emailReminderLeadTime}
							enableConfirmationEmailInstructions={true}
							enableConfirmationEmailOptIn={true}
							enableConfirmationEmailOther={true}
							enableEmailReminderInstructions={true}
							enableSmsReminderOptions={true}
							enableVoiceReminderOptions={true}
							fieldValidation={this.state.fieldValidation}
							formValidation={this.state.formValidation}
							handleChange={this.handleChange}
							handleCheckboxChange={this.handleCheckboxChange}
							handleDropdownChange={this.handleDropdownChange}
							isRequestAppointment={true}
							maxReminderLeadHours={300}
							patientConfirmationEmailDisclaimer={this.props.patientConfirmationEmailDisclaimer}
							patientDetails={this.props.patientDetails}
							patientReminderEmailDisclaimer={this.props.patientReminderEmailDisclaimer}
							patientReminderSmsDisclaimer={this.props.patientReminderSmsDisclaimer}
							patientReminderVoiceDisclaimer={this.props.patientReminderVoiceDisclaimer}
							phones={this.state.phones}
							reminderEmailOther={this.state.reminderEmailOther}
							selectedLanguage={this.state.selectedLanguage}
							shouldSendRequestConfirmationEmail={this.state.shouldSendRequestConfirmationEmail}
							showEmailConfirmation={this.props.emailConfirmationEnabled}
							showEmailReminder={true}
							showTextReminder={true}
							showTopHorizontalDivider={true}
							showVoiceReminder={true}
							textReminderConsent={this.state.textReminderConsent}
							textReminderLeadTime={this.state.textReminderLeadTime}
							textReminderNumber={this.state.textReminderNumber}
							textReminderOtherNumber={this.state.textReminderOtherNumber}
							title={patientNotificationsLabel}
							toggle={this.togglePatientNotifications}
							voiceReminderConsent={this.state.voiceReminderConsent}
							voiceReminderLeadTime={this.state.voiceReminderLeadTime}
							voiceReminderNumber={this.state.voiceReminderNumber}
							voiceReminderOtherNumber={this.state.voiceReminderOtherNumber}
						/>
						{this.props.schedulingConfig.reasonForVisit.isEnabled && (
							<React.Fragment>
								{reasonForVisitLabel}
								<ReasonForVisit
									characterLimit={this.props.schedulingConfig.reasonForVisitCharacterLimit}
									config={this.props.schedulingConfig.scheduling}
									fieldValidation={this.state.fieldValidation}
									handleChange={this.handleChange}
									reasonForVisit={this.state.reasonForVisit}
								/>
							</React.Fragment>
						)}
						{this.props.schedulingConfig.enableAddAttachments && (
							<React.Fragment>
								{attachmentsLabel}
								<Attachments
									config={this.props.schedulingConfig}
									fileError={this.state.fileError}
									files={this.state.files}
									onCancel={this.onCancel}
									onDrop={this.onDrop}
									onDropRejected={this.onDropRejected}
									removeFile={this.removeFile}
								/>
							</React.Fragment>
						)}
						{schedulingInformationLabel}
						<SchedulingInformation
							characterLimit={this.props.schedulingConfig.reasonForVisitCharacterLimit}
							config={this.props.schedulingConfig.scheduling}
							fieldValidation={this.state.fieldValidation}
							handleChange={this.handleChange}
							schedulingInformation={this.state.schedulingInformation}
						/>
						{!this.state.isCustomFieldsLoading && (
							<CustomFields
								customFieldAnswers={this.state.customFieldAnswers}
								customFields={shownCustomFields}
								customFieldValidation={this.state.customFieldValidation}
								handleCustomFieldChange={this.handleCustomFieldChange}
								handleCustomFieldDateChange={this.handleCustomFieldDateChange}
								handleCustomFieldDropdownChange={this.handleCustomFieldDropdownChange}
							/>
						)}
						<hr />
						<Row className="request-appointment-buttons">
							<Col lg="12">
								<div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
									<Button
										color="primary"
										disabled={disableSubmitButton}
										onClick={this.onSubmitRequestClick}
										style={{ marginRight: '8px' }}
									>
										Submit Appointment Request
									</Button>
									<Button color="secondary" onClick={this.onCancelClick} style={{ marginRight: '8px' }}>
										Cancel
									</Button>
								</div>
							</Col>
						</Row>
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		auth: state.auth,
		availability: state.availability,
		availabilitySearchConfig: state.config.availabilitySearch,
		availabilitySearchSupportData: state.config.availabilitySearchSupportData,
		careOrderConfig: state.config.careOrder,
		consumerProductInstances: state.config.consumerProductInstances,
		customFields: state.customFields,
		decisionSupport: state.config.decisionSupport,
		emailConfirmationEnabled: state.config.notification.emailConfirmationEnabled,
		emailReminderEnabled: state.config.notification.emailReminderEnabled,
		enableConfirmationEmailOptIn: state.config.notification.showPatientConfirmationEmailOptIn,
		hasMultipleProductInstances: false,
		isGroupNumberActive: state.config.system.isGroupNumberActive,
		isLoading: state.ajaxStatus.callsInProgressCount > 0,
		isMemberIdActive: state.config.system.isMemberIdActive,
		memberIdFieldLabel: state.config.system.memberIdFieldLabel,
		notificationConfig: state.config.notification,
		orderManagementConfig: state.config.orderManagement,
		patientConfig: state.config.patient,
		patientConfirmationEmailDisclaimer: state.config.notification.patientConfirmationEmailDisclaimer,
		patientDetails: state.activePatient.details,
		patientReferenceId: state.activePatient.details.referenceId,
		patientReminderEmailDisclaimer: state.config.notification.patientReminderEmailDisclaimer,
		patientReminderSmsDisclaimer: state.config.notification.patientReminderSmsDisclaimer,
		patientReminderVoiceDisclaimer: state.config.notification.patientReminderVoiceDisclaimer,
		providerDetails: state.provider.details,
		providerFieldConfig: state.config.provider,
		referralSiteId: state.auth.referralSiteId,
		referralSystemId: state.auth.referralSystemId,
		reservation: state.appointment.reservation,
		schedulingConfig: state.config.scheduling,
		session: state.session,
		symptomDetails: state.appointment.symptomDetails,
		systemConfig: state.config.system,
		textReminderEnabled: state.config.notification.textReminderEnabled,
		token: state.auth.token,
		voiceReminderEnabled: state.config.notification.voiceReminderEnabled,
		isLoadingSearchState: { anySearch: false, isReserving: false },
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: {
			appointment: bindActionCreators(appointmentActions, dispatch),
			rd2RefactorActions: bindActionCreators(rd2RefactorActions, dispatch),
			provider: bindActionCreators(providerActions, dispatch),
		},
		dispatch,
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestAppointmentView));
